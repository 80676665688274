import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function Logout() {
    const navigate = useNavigate();

    try {
        localStorage.clear();
    } catch (err) {
        console.error(err.message);
    }

    useEffect(() => navigate('/login'), [ navigate ]);
}

export default Logout;