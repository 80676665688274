import CryptoJS from "crypto-js";

const hashSHA512 = (text) => {
    return CryptoJS.SHA512(text).toString();
}

const encryptAES = (text, secret) => {
    return CryptoJS.AES.encrypt(text, secret).toString();
}

const encryptAESText = (text) => {
    let encryptedText = "";

    if (localStorage.dataKey) {
        encryptedText = encryptAES(text, localStorage.dataKey);
    }
        
    return encryptedText;
}

const decryptAES = (text, secret) => {
    return CryptoJS.AES.decrypt(text, secret).toString(CryptoJS.enc.Utf8);
}

const decryptAESText = (text) => {
    let decryptedText = "";

    if (localStorage.dataKey) {
        decryptedText = decryptAES(text, localStorage.dataKey);
    }

    return decryptedText;
}

const decryptDataSet = (data) => {
    let decryptedDataEntry = null;

    if (Array.isArray(data)) {
        decryptedDataEntry = decryptDataArray(data);
    } else if (typeof(data) === 'object') {
        if (data.decrypted) {
            decryptedDataEntry = data.data;
        } else {
            decryptedDataEntry = decryptDataObject(data);
        }
    } else if (typeof(data) === 'string') {
        decryptedDataEntry = decryptAESText(data);
    } else {
        decryptedDataEntry = data;
    }

    return decryptedDataEntry;
}
const decryptDataArray = (data) => {
    let decryptedDataArray = [];

    for(let i = 0; i < data.length; i++) {
        decryptedDataArray[i] = decryptDataSet(data[i]);
    }
    
    return decryptedDataArray;
}

const decryptDataObject = (data) => {
    let decryptedDataObject = {};
    
    for (let key in data) {
        if ((key !== "username") && (key !== "owner") && ((key !== "timestamp"))) {
            decryptedDataObject[key] = decryptDataSet(data[key]);
        } else {
            decryptedDataObject[key] = data[key];
        }
    }

    return decryptedDataObject;
}

const encryptDataObject = (data) => {
    let encryptedDataObject = {};

    if (data.decrypted) {
        encryptedDataObject = data.data;
    } else {
        for (let key in data) {
            if (typeof(data[key]) === 'object') {
                encryptedDataObject[key] = encryptDataObject(data[key]);
            } else if ((key !== "username") && (typeof(data[key]) === 'string')) {
                encryptedDataObject[key] = encryptAESText(data[key]);
            } else {
                encryptedDataObject[key] = data[key];
            }
        }
    }

    return encryptedDataObject;
}

export {
    hashSHA512,
    encryptAES,
    encryptAESText,
    decryptAES,
    decryptAESText,

    decryptDataSet,
    encryptDataObject
}