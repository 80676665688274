import { useEffect, useState } from "react";

import { EventConfirmationButton } from "../../components/modals/EventConfirmationModal";
import { EventResponseButton } from "../../components/modals/EventResponseModal";

import styles from "./Workplace.module.css"

function TaskEditorTab({ id, title, description, isEditingDisabled, updateTaskHandler, convertTaskToStoryHandler }) {
    const [ editedTaskTitle, setEditedTaskTitle ] = useState(title);
    useEffect(() => setEditedTaskTitle(title), [ title ]);
    const [ editedTaskDescription, setEditedTaskDescription ] = useState(description);
    useEffect(() => setEditedTaskDescription(description), [ description ]);

    return (
        <>      
            <div className="modal-body pb-1">
                <input 
                    data-bs-theme="dark"
                    type="text"
                    className="w-100"
                    value={ editedTaskTitle }
                    onChange={ event => setEditedTaskTitle(event.target.value) }
                    disabled={ isEditingDisabled } />
                <textarea
                    data-bs-theme="dark"
                    className={ `${ styles['textarea-task-description'] } w-100 mt-2` }
                    rows="7"
                    value={ editedTaskDescription }
                    onChange={ event => setEditedTaskDescription(event.target.value) }
                    disabled={ isEditingDisabled } />
            </div>
            
            <div className="modal-footer justify-content-between border-0 pt-0">
                <EventConfirmationButton
                    text="Umwandeln"
                    eventFunction={ async () => await convertTaskToStoryHandler(id) }
                    confirmationButtonText="Umwandeln"
                    confirmationButtonLoadingText="Umwandeln..." 
                    modalSuccessTitle={ title } 
                    modalSuccessContent="Die Aufgabe wurde erfolgreich umgewandelt"
                    modalEventConfirmationTitle={ title }
                    modalEventConfirmationContent="Soll die Aufgabe in einen Aufgabenblock umgewandelt werden?" />

                <EventResponseButton
                    className="btn btn-primary"
                    text="Speichern"
                    loadingText="Speichern..."
                    modalSuccessTitle={ title }
                    modalSuccessContent="Die Aufgabe wurde erfolgreich aktualisiert"
                    event={ () => updateTaskHandler(id, editedTaskTitle, editedTaskDescription) }
                    disabled={ isEditingDisabled } />
            </div>
        </>
    );
}

export default TaskEditorTab;