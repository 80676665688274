import styles from "../sites/Workplace/Workplace.module.css";

function BarElementx1({ title, subtitle, pointsElement, isHighlighted, progressStatus, icon }) {
    return (
        <div className={ `d-flex w-100 ${ (isHighlighted) ? styles['story-btn-planned'] : styles['story-btn'] }` }>
            { (icon) &&
                <div className="d-flex align-items-center pe-3">
                    <img src={ icon } className={ `${ styles['icon-story'] }` } alt="button icon" />
                </div>
            }
            <div className="d-flex flex-column w-100">
                <div className={ `d-flex justify-content-between ${ styles['story-btn-title']}` }>
                    <div className="d-flex align-items-center">
                        { title }
                    </div>
                    <div className="pb-1">
                        { pointsElement }
                    </div>
                </div>
                <div className="progress w-100 my-1" role="progressbar">
                    <div className="progress-bar" style={{ width: `${ progressStatus }%` }}></div>
                </div>
                <div className={ `align-self-center ${ styles['story-btn-status']}` }>
                    { subtitle }
                </div>
            </div>
        </div>
    );
}

export default BarElementx1;