import BCoins from "../../components/BCoins";

import workoutIcon from "../../../img/activity_workout.png";


import styles from "../Activities/Activities.module.css";

function Activity({ bCoins, timestamp }) {
    const userrole = parseInt(localStorage.userrole);

    const title = ( () => {
        switch (userrole) {
            case 100:
                return "b0ne war beim Sport";
            default:
                return "Du warst beim Sport";
        }
    })();
    
    //@TODO: Funktion in Utils-Datei als dateFormatter-Funktion
    const dateTimestamp = new Date(timestamp);
    const day = `${ (dateTimestamp.getDate() < 10) ? "0" : "" }${ dateTimestamp.getDate() }`;
    const month = `${ (dateTimestamp.getMonth() < 9) ? "0" : "" }${ dateTimestamp.getMonth() + 1 }`;
    const year = `${ dateTimestamp.getFullYear() }`;
    const hour = `${ (dateTimestamp.getHours() < 10) ? "0" : ""}${ dateTimestamp.getHours() }`;
    const minute = `${ (dateTimestamp.getMinutes() < 10) ? "0" : ""}${ dateTimestamp.getMinutes() }`;

    const stringTimestamp = `${ day }.${ month }.${ year } - ${ hour }:${ minute }`;

    return (
        <div className={ `d-flex justify-content-between w-100 ${ styles['activity-bar'] }` }>
            <div className="d-flex align-items-center">
                <img src={ workoutIcon } className={ `${ styles['icon-activity'] } pe-3` } alt="icon" />
                
                <div>    
                    <div className={ styles['activity-bar-title'] }>
                        { title }
                    </div>

                    <div className={ `${ styles['activity-bar-date'] }` }>
                        { stringTimestamp }
                    </div>
                </div>
            </div>

            <div className="d-flex align-items-center">
                <BCoins value={ bCoins } />
            </div>
        </div>
    );
}

export default Activity;