import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Offcanvas from "react-bootstrap/Offcanvas";

import styles from "./AuthenticatedArea.module.css";

import Userstats from "./Userstats";

//@TODO: Get profile-pic from database (also in ../members/Profil.js)
import imgProfile_F from "../../img/profile_F.png";
import imgProfile_B from "../../img/profile_B.png";
import imgLogo from "../../img/logo.png";

function Navigation({ balance, bCoins }) {
    const username = localStorage.username;
    const userrole = parseInt(localStorage.userrole);

    const imgProfile = (userrole === '100') ? imgProfile_F : imgProfile_B;

    const expand = "md";

    return (
        <Navbar expand={ expand } className="bg-primary">
            <Container fluid>
                <Navbar.Brand href="/" className="ps-3">
                    <img src={ imgLogo } className={ styles['img-logo'] } alt="logo" />
                </Navbar.Brand>

                <Nav className={`d-${expand}-flex d-none align-items-center w-100 justify-content-end pe-3`}>
                    <Nav.Link href="activities" className="active">Online-Aktivitäten</Nav.Link>
                    { /*<Nav.Link href="workplace" className="active">Aufgabenliste</Nav.Link>*/ }
                    <Nav.Link href="meetups" className="active">Treffen</Nav.Link>
                    <Nav.Link href="rewards" className="active">Belohnungen</Nav.Link>
                    <Nav.Link href="workouts" className="active">Workouts</Nav.Link>

                    <NavDropdown
                        align="end"
                        title={
                            <Image className={ styles['img-profile-untoggled'] } src={ imgProfile } />
                        }
                        id={`offcanvasNavbarDropdown`} >
                        
                        <NavDropdown.Header className="bg-primary">
                            <h5 className="d-flex flex-column py-2">
                                <div className={ `d-flex justify-content-center pb-2` }>
                                    { username }
                                </div>
                            </h5>
                                
                            <Userstats balance={ balance } bCoins={ bCoins } />
                        </NavDropdown.Header>
                        
                        <NavDropdown.Item className="mt-3" href="settings">
                            Einstellungen
                        </NavDropdown.Item>
                        <hr />
                        { (userrole === 101)  &&
                            <>
                                <NavDropdown.Item className="mt-3" href="management">
                                    Management
                                </NavDropdown.Item>
                                <hr />
                            </>
                        }
                        <NavDropdown.Item className="mb-3" href="logout">
                            Logout
                        </NavDropdown.Item>
                    </NavDropdown>
                </Nav>

                <Navbar.Toggle className={ styles['btn-toggle'] } />
            
                <Navbar.Offcanvas
                    id={`offcanvasNavbar-expand-${expand}`}
                    className={`d-${expand}-none w-75`}
                    aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                    placement="end">
                    
                    <Offcanvas.Header className="flex-column-reverse align-items-end bg-primary" closeVariant="white" closeButton>
                        <Offcanvas.Title className="w-100 d-flex flex-column align-items-center" id={`offcanvasNavbarLabel-expand-${expand}`}>
                            <Image className={ styles['img-profile-toggled'] } src={ imgProfile } />
                            <div className="my-2">{ username }</div>
                            
                            <Userstats balance={ balance } bCoins={ bCoins } />
                        </Offcanvas.Title>
                    </Offcanvas.Header>

                    <Offcanvas.Body>
                        <Nav className={`align-items-end flex-grow-1`}>
                            <Nav.Link href="meetups">Treffen</Nav.Link>
                            <Nav.Link href="activities">Online-Aktivitäten</Nav.Link>
                            { /*<Nav.Link href="workplace">Aufgabenliste</Nav.Link>*/ }
                            <Nav.Link href="rewards">Belohnungen</Nav.Link>
                            <Nav.Link href="workouts">Workouts</Nav.Link>
                            <hr className="w-100" />
                            <Nav.Link href="settings">Einstellungen</Nav.Link>
                            
                            { (userrole === 101) &&
                                <>
                                    <hr className="w-100" />
                                    <Nav.Link href="management">Management</Nav.Link>
                                </>
                            }
                            <hr className="w-100" />
                            <Nav.Link href="logout">Logout</Nav.Link>
                        </Nav>
                    </Offcanvas.Body>
                </Navbar.Offcanvas>
            </Container>
        </Navbar>
    );
}

export default Navigation;