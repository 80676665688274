import Spinner from "react-bootstrap/Spinner";

function LoadingButton({ id, className, text, loadingText, isLoading, onClick, disabled }) {
    if (isLoading) {
        return (
            <button type="button" id={ id } className={ className } onClick={ onClick } disabled>
                <Spinner animation="border" size="sm"/>
                { ` ${ loadingText }` }
            </button>
        )
    }

    return (
        <button type="button" id={ id } className={ className } onClick={ onClick } disabled={ disabled }>{ text }</button>
    );
}

export default LoadingButton;