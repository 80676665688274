import { useState } from "react";
import { useLoaderData } from "react-router-dom";

import { Modal } from "bootstrap/dist/js/bootstrap.esm.min";

import CRUDfunctions from "../../lib/CRUDbackend";

import LoadingButton from "../components/LoadingButton";

import Workout from "./Workouts/Workout";

import styles from "./Activities/Activities.module.css";
import stylesAddBtn from "./Workplace/Workplace.module.css";

import successIcon from "../../img/check_tick.png";

function Workouts({ reloadUserStatsHandler }) {
    const userrole = parseInt(localStorage.userrole);

    const workoutData = useLoaderData();

    const title = "Workouts";

    const dataPeriod = workoutData.dataPeriod;

    ///////////////////////////////////////////////////////////////////////////////////////////////
    //@TODO: muss in eine utility-Funktion (wird auch in Activity verwendet)
    const startDate = new Date(dataPeriod.start);
    const startDay = `${ (startDate.getDate() < 10) ? "0" : "" }${ startDate.getDate() }`;
    const startMonth = `${ (startDate.getMonth() < 9) ? "0" : "" }${ startDate.getMonth() + 1 }`;
    const startYear = `${ startDate.getFullYear() }`;
    const workoutsPeriodStart = `${ startDay }.${ startMonth }.${ startYear }`;

    const endDate = new Date(dataPeriod.end);
    const endDay = `${ (endDate.getDate() < 10) ? "0" : "" }${ endDate.getDate() }`;
    const endMonth = `${ (endDate.getMonth() < 9) ? "0" : "" }${ endDate.getMonth() + 1 }`;
    const endYear = `${ endDate.getFullYear() }`;
    const workoutsPeriodEnd = `${ endDay }.${ endMonth }.${ endYear }`;
    ///////////////////////////////////////////////////////////////////////////////////////////////

    const [ workouts, setWorkouts ] = useState(workoutData.workouts);

    const [ isLoadingCreateWorkoutButton, setIsLoadingCreateWorkoutButton ] = useState(false);

    const createWorkoutHandler = async () => {
        const createdWorkout = await CRUDfunctions.create("/workouts/", {});
        await reloadUserStatsHandler();

        setWorkouts([
            createdWorkout,
            ...workouts
        ]);
    }

    const handleCreateWorkoutButtonClick = async () => {
        setIsLoadingCreateWorkoutButton(true);

        //@TODO: Error-handling
        await createWorkoutHandler();

        const closeButtonCreateWorkout = document.getElementById("buttonCloseCreateWorkout");
        closeButtonCreateWorkout.click();

        //Open the success-modal
        const modalCreateWorkoutSuccess = new Modal(document.getElementById("modalCreateWorkout-success"));
        modalCreateWorkoutSuccess.show();

        //Wait till modal transmission is completed
        setTimeout(() => setIsLoadingCreateWorkoutButton(false), 200);
    }

    return (
        <>
            <h2 className="d-flex justify-content-center w-100">
                { title }
            </h2>

            <div className="accordion py-3">
                <div className="accordion-item">
                    <h2 className="accordion-header">
                        <div className="accordion-button">
                            <div className={ `d-flex w-100 justify-content-center ${ styles['headline-week'] }` }>
                                { workoutsPeriodStart } - { workoutsPeriodEnd }
                            </div>
                        </div>
                    </h2>

                    <div className="accordion-collapse collapse show">
                        <div className="accordion-body">                            
                            <div>
                                { workouts.map( workout => {
                                    return <Workout
                                                key={ workout.id }
                                                bCoins={ workout.bCoins }
                                                timestamp={ workout.timestamp } />
                                }) }

                                { (workouts.length === 0) &&
                                    <div className="px-2 py-2">
                                        Diese Woche wurden noch keine Workouts gemacht...
                                    </div>
                                }

                                {/*@TODO: AddButton soll von Workplace in den Komponenten-Ordner und ein Modal übergeben bekommen*/}
                                {/*@TODO: Ist das GLeiche wie der EventConfirmationModal, aber wie kann man das Aussehen des Buttons ändern? */}
                                { (userrole !== 100) &&
                                    <>
                                        <div className="d-flex justify-content-end px-3 py-3">
                                            {/*<button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target={ `#modalNewTask-${ id }` }>*/}
                                                { /*@TODO: Button soll ein Kreis mit einem + sein (Schriftfarbe weiß?) */ }
                                                <i className={ `fa-solid fa-circle-plus ${ stylesAddBtn['btn-add-task'] }` } data-bs-toggle="modal" data-bs-target="#modalCreateWorkout"></i>
                                            {/*</button>  */}
                                        </div>

                                        <div className="modal fade" id="modalCreateWorkout" tabIndex="-1" aria-hidden="true">
                                            <div className="modal-dialog modal-dialog-centered">
                                                <div className="modal-content">
                                                    <div className="modal-header border-0">
                                                        <h1 className="modal-title fs-5">
                                                            Erstellen eines Workouts
                                                        </h1>
                                                        <button type="button" id="buttonCloseCreateWorkout" className="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close"></button>
                                                        
                                                    </div>

                                                    <div className="modal-body py-0">
                                                        Hast du gerade ein Workout abgeschlossen?
                                                    </div>
                                                    
                                                    <div className="modal-footer border-0 pt-0">
                                                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Nein</button>
                                                        { /*@TODO: Erstellen-Button soll ein LoadingButton sein*/}
                                                        <LoadingButton
                                                            className="btn btn-primary"
                                                            text="Ja"
                                                            loadingText="Erstellen..."
                                                            isLoading={ isLoadingCreateWorkoutButton }
                                                            onClick={ handleCreateWorkoutButtonClick } />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="modal fade" id="modalCreateWorkout-success" tabIndex="-1">
                                            <div className="modal-dialog modal-dialog-centered">
                                                <div className="modal-content">
                                                    <div className="modal-header border-0">
                                                        <h1 className="modal-title fs-5">
                                                            Erstellen eines Workouts
                                                        </h1>
                                                    </div>

                                                    <div className="modal-body d-flex align-items-center p-0">
                                                        <img src={ successIcon } className={ `${ stylesAddBtn['icon-deleteTaskStatus'] } mx-4` } alt="task icon" />
                                                        <div>Das Workout wurde erfolgreich erstellt</div>
                                                    </div>

                                                    <div className="modal-footer border-0 pt-0">
                                                        <button type="button" className="btn btn-primary" data-bs-dismiss="modal">Schließen</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Workouts;