import { redirect } from "react-router-dom";

import { decryptDataSet, encryptDataObject } from "./Encryption";

//@TODO: Alle Funktionen können zusammengefasst werden wie unten mit writeData
//      => GET bekommt leeren body übergeben
const read = async (route) => {
    try {
        if (localStorage.token) {
            const res = await fetch(process.env.REACT_APP_BACKEND_URL + route, {
                method: "GET",
                headers: {
                    token: localStorage.token
                }
            });

            if (res.status === 200) {
                const decryptedResponse = decryptDataSet(await res.json());
                return decryptedResponse;
            } /*else {
                localStorage.clear();
                return { error: "NOT_LOGGED_IN" };
            }
        } else {
            localStorage.clear();
            return { error: "NOT_LOGGED_IN" };*/
        }
    } catch (err) {
        console.error(err.message);
    }
}

const create = async (route, data) => await writeData("POST", route, data);

const update = async (route, data) => await writeData("PUT", route, data);

const drop = async (route, data) => await writeData("DELETE", route, data);

const writeData = async (method, route, data) => {
    const encryptedData = encryptDataObject(data);

    try {
        if (localStorage.token) {
            const res = await fetch(process.env.REACT_APP_BACKEND_URL + route, {
                method: method,
                headers: {
                    "Content-type": "application/json",
                    token: localStorage.token
                },
                body: JSON.stringify(encryptedData)
            });
            
            const decryptedResponse = decryptDataSet(await res.json());
            return decryptedResponse;
        } else {
            localStorage.clear();
            return redirect("/login");
        }
    } catch (err) {
        console.error(err.message);
    }
}

const CRUDfunctions = {
    create,
    read,
    update,
    drop
};

export default CRUDfunctions;