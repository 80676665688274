import {
  Navigate,
  Outlet, 
  useLocation,
  useLoaderData,
  useNavigation } from "react-router-dom";

import Spinner from "react-bootstrap/Spinner";

import Footer from "./AuthenticatedArea/Footer";
import Navigation from "./AuthenticatedArea/Navigation";

import styles from "./AuthenticatedArea/AuthenticatedArea.module.css";

function AuthenticatedArea({ loadingState, userStats }) {
  const location = useLocation();
  const navigation = useNavigation();

  const authenticatedState = useLoaderData();
  if (!authenticatedState) {
    return <Navigate to="/login" replace state={ { location } } />;
  }

  const spinnerStyle = { position: "fixed", top: "40%", left: "calc(50% - 1rem)" };

  return (
    <>
      { (loadingState) && <Spinner animation="border" style={ spinnerStyle } /> }
      <div className={ styles['rows-grid'] } style={{ flex: 1, opacity: loadingState ? 0.25 : 1 }}>
          <Navigation
            balance={ userStats.balance }
            bCoins={ userStats.bCoins } />

          <div className={ styles['content-area'] }>
              { (navigation.state === 'loading') && "Lade Daten..."}
              { (navigation.state !== 'loading') && <Outlet /> }
          </div>

          <Footer />
      </div>
    </>
  );
}

export default AuthenticatedArea;