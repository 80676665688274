import balanceIcon from "../../img/balance.png";

import styles from "../sites/Activities/Activities.module.css";

function Balance({ balance, className, scaling }) {
    return (
        <div className={ `d-flex ${ (className) && className }` } style={{ transform: `scale(${ (scaling) ? scaling : 1 })` }}>
            <div>
                <img src={ balanceIcon } className={ `${ styles['icon-activityPoints'] } pe-1` } alt="activity points icon" />
            </div>

            <div className={ `${ styles['font-activityPoints'] }` } >{ balance.toFixed(2) }€</div>
        </div>
    );
}

export default Balance;