import { useState } from "react";
import { createPortal } from "react-dom";

import successIcon from "../../../img/check_tick.png";
import LoadingButton from "../LoadingButton";

import styles from "./Modals.module.css";

function EventResponseButton({ className, text, loadingText, modalSuccessTitle, modalSuccessContent, event, disabled }) {
    const [ isLoading, setIsLoading ] = useState(false);

    const handleButtonClick = async () => {
        setIsLoading(true);

        const eventFunction = (event) ? event : document.getElementById("hiddenEventFunction").onclick;

        const eventSuccess = await eventFunction();
        
        //if (eventSuccess) {
            //const successModal = new Modal(document.getElementById("successModal"));
            const successModalTitle = document.getElementById("successModal-title");
            successModalTitle.innerHTML = modalSuccessTitle;
            const successModalContent = document.getElementById("successModal-content");
            successModalContent.innerHTML = modalSuccessContent;

            const openSuccessModal = document.getElementById("openSuccessModal");
            openSuccessModal.click();
        //} else { //ErrorModal }

        setTimeout(() => setIsLoading(false), 300);
    }

    return (
        <>
            <button id="hiddenEventFunction" hidden />
            <LoadingButton
                text={ text }
                loadingText={ loadingText }
                className={ className }
                onClick={ handleButtonClick }
                isLoading={ isLoading }
                disabled={ disabled } />
        </>
    );
}

function SuccessModal() {
    return createPortal(
        <>
            <button id="openSuccessModal" type="button" data-bs-toggle="modal" data-bs-target="#successModal" hidden />

            <div className="modal fade" id="successModal" tabIndex="-1">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header border-0">
                            <h1 id="successModal-title" className="modal-title fs-5" />
                        </div>

                        <div className="modal-body d-flex align-items-center p-0">
                            <img src={ successIcon } className={ `${ styles['icon-success'] } mx-4` } alt="task icon" />
                            <div id="successModal-content" className="pe-2"/>
                        </div>

                        <div className="modal-footer border-0 pt-0">
                            <button type="button" className="btn btn-primary" data-bs-dismiss="modal">Schließen</button>
                        </div>
                    </div>
                </div>
            </div>    
        </>,
        document.body
    );
}

export {
    EventResponseButton,
    SuccessModal
}