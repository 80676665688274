import Card from "react-bootstrap/Card";

function Content({ title, children: content }) {
    return (
        <Card.Body>
            <Card.Title className="d-flex justify-content-center">
                { title }
            </Card.Title>

            { content }
        </Card.Body>
    );
}

export default Content;