import BCoins from "../../components/BCoins";

import textIcon from "../../../img/activity_text.png";
import voiceIcon from "../../../img/activity_voice.png";
import imageIcon from "../../../img/activity_image.png";
import imageMessageIcon from "../../../img/category_image.png";
import imageObjectMessageIcon from "../../../img/category_image_object.png";


import styles from "./Activities.module.css";

function Activity({ owner, type, bCoins, timestamp }) {
    const userrole = parseInt(localStorage.userrole);

    //@Doku: Kategorisieren eines Bildes
    const updateMessageType = (messageType) => {
        console.log(messageType, "Logged");
    }

    const title = ( () => {
        switch (type) {
            case "Text":
                if (userrole === 100) {
                    return "Du hast eine Nachricht gesendet";
                } else {
                    return `${ owner } hat eine Nachricht gesendet`;
                }
            case "Voice":
                if (userrole === 100) {
                    return "Du hast eine Sprachnachricht gesendet";
                } else {
                    return `${ owner } hat eine Sprachnachricht gesendet`;
                }
            default: //"Image", "Image_object", "Uncategorised":
                if (userrole === 100) {
                    return "Du hast ein Foto gesendet";
                } else {
                    return `${ owner } hat ein Foto gesendet`;
                }
        }
    } )();

    const icon = ( () => {
        switch (type) {
            case "Text":
                return textIcon;
            case "Voice":
                return voiceIcon;
            default: //"Image", "Image_object", "Uncategorised":
                return imageIcon;
        }
    })();
    
    //@TODO: muss in eine utility-Funktion (wird auch in MeetupData verwendet)
    const dateTimestamp = new Date(timestamp);
    const day = `${ (dateTimestamp.getDate() < 10) ? "0" : "" }${ dateTimestamp.getDate() }`;
    const month = `${ (dateTimestamp.getMonth() < 9) ? "0" : "" }${ dateTimestamp.getMonth() + 1 }`;
    const year = `${ dateTimestamp.getFullYear() }`;
    const hour = `${ (dateTimestamp.getHours() < 10) ? "0" : ""}${ dateTimestamp.getHours() }`;
    const minute = `${ (dateTimestamp.getMinutes() < 10) ? "0" : ""}${ dateTimestamp.getMinutes() }`;

    const stringTimestamp = `${ day }.${ month }.${ year } - ${ hour }:${ minute }`;

    return (
        <div className={ `d-flex justify-content-between w-100 ${ styles['activity-bar'] }` }>
            <div className="d-flex align-items-center">
                <img src={ icon } className={ `${ styles['icon-activity'] } pe-3` } alt="icon" />
                
                <div>    
                    <div className={ styles['activity-bar-title'] }>
                        { title }
                    </div>

                    <div className={ `${ styles['activity-bar-date'] }` }>
                        { stringTimestamp }
                    </div>
                </div>
            </div>

            <div className="d-flex align-items-center">
                <BCoins value={ bCoins } />

                { /*
                    { (type !== "Uncategorised") && 
                        <ActivityPoints points={ points } />
                    }
                    { (type === "Uncategorised") &&
                        <>
                            { (userrole === 100) &&
                                <div className={ `spinner-border text-light me-3 ${ styles['spinner-categorising'] }` } role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            }
                            { (userrole === 101) &&
                                <div className="d-flex">
                                    <img src={ imageMessageIcon } className={ `${ styles['icon-category'] } me-3` } onClick={ () => updateMessageType("Image") } alt="" />
                                    <img src={ imageObjectMessageIcon } className={ `${ styles['icon-category'] }` } onClick={ () => updateMessageType("Image_object") } alt="" />
                                </div>
                            }
                        </>
                    }
                */ }
            </div>
        </div>
    );
}

export default Activity;