import { useLoaderData } from "react-router-dom";

import CRUDfunctions from "../../lib/CRUDbackend";

import { EventConfirmationButton, EventConfirmationModal } from "../components/modals/EventConfirmationModal_general";
import { SuccessModal } from "../components/modals/EventResponseModal";

import MeetupModal from "../components/modals/MeetupModal";
import MeetupData from "./Meetups/MeetupData";

function Meetups() {
    const userrole = parseInt(localStorage.userrole);

    const meetupData = useLoaderData();

    const nextMeetups = meetupData.nextMeetups;
    const completedMeetups = meetupData.completedMeetups;

    const title = "Treffen"

    const completeMeetupHandler = async (meetupId) => {
        //@TODO: Aktuell kann nur ein Meetup für b0ne offen sein => muss für jede Userin eines geben
        const meetupData = {
            meetupId: meetupId
        };

        const completedMeetupResult = await CRUDfunctions.update("/rewards/meetups/complete_meetup", meetupData);
    }

    //@TODO: Mögliche Verbesserung: Nicht für jede Userin gibt es eine eigenes Accordion, sondern in einem Accordion gibt es Unter-Accrodions (wie im Workplace) (nur falls es mehrere nächste Treffen gibt)
    //@TODO: Das succesModal kann mit createPortal auch im EventConfirmationModal importiert werden
    return (
        <>
            <MeetupModal.Modal />
            <EventConfirmationModal />
            <SuccessModal />

            <h2 className="d-flex justify-content-center w-100">
                { title }
            </h2>

            { nextMeetups.map( nextMeetupData => {
                return (
                <div key={ nextMeetupData.id } className="accordion py-3">
                    <div className="accordion-item">
                        <h2 className="accordion-header">
                            <div className="accordion-button" data-bs-toggle={ (userrole === 101) && "collapse" }  data-bs-target={ `#nextMeetup-${ nextMeetupData.id }` }>
                                Nächstes Treffen { (userrole === 101) && `mit ${ nextMeetupData.organizer }` }
                            </div>
                        </h2>

                        <div id={ `nextMeetup-${ nextMeetupData.id }` } className={ `accordion-collapse collapse ${ (nextMeetups.length === 1) && "show" }`} >
                            <div className="accordion-body">                            
                                <div>
                                    <MeetupData
                                        timestamp={ nextMeetupData.timestamp }
                                        reward={ nextMeetupData.reward }
                                        isCompleted={ false }
                                        bCoinsFromReward={ nextMeetupData.bCoinsFromReward.bCoins }
                                        cashoutFromReward={ nextMeetupData.cashoutFromReward }
                                        balance={ nextMeetupData.balance }
                                        cashoutFromBalance={ nextMeetupData.cashoutFromBalance }
                                        totalCashout={ nextMeetupData.totalCashout }
                                        comment={ nextMeetupData.comment } />

                                    { (userrole === 101) &&
                                        <div className="d-flex w-100 justify-content-end pt-2 pb-3 pe-3">
                                            <EventConfirmationButton
                                                eventFunction={ async () => await completeMeetupHandler(nextMeetupData.id) }
                                                confirmationButtonText="Abschließen"
                                                confirmationButtonLoadingText="Abschließen..."
                                                modalSuccessTitle="Treffen abschließen"
                                                modalSuccessContent="Das Treffen wurde erfolgreich abgeschlossen"
                                                modalEventConfirmationTitle="Treffen abschließen"
                                                modalEventConfirmationContent="Soll das Treffen wirklich abgeschlossen werden?" >
                                                
                                                <button type="button" className="btn btn-primary">
                                                    Treffen abschließen
                                                </button>
                                            </EventConfirmationButton>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )} )}

            <div className="accordion py-3">
                <div className="accordion-item">
                    <h2 className="accordion-header">
                        <div className="accordion-button">
                            Vergangene Treffen
                        </div>
                    </h2>

                    <div className="accordion-collapse collapse show">
                        <div className="accordion-body">
                            { completedMeetups.map( meetup => {
                                return <MeetupModal.Button
                                    key={ meetup.id }
                                    timestamp={ meetup.timestamp } 
                                    reward={ meetup.reward }
                                    bCoinsFromReward={ meetup.bCoinsFromReward.bCoins }
                                    cashoutFromReward={ meetup.cashoutFromReward }
                                    cashoutFromBalance={ meetup.cashoutFromBalance }
                                    totalCashout={ meetup.totalCashout }
                                    comment={ meetup.comment }
                                    organizer={ (userrole === 101) ? meetup.organizer : "" } />
                            }) }
                        </div>
                    </div>
                </div>
            </div>
        </>
        
    );
}

export default Meetups;