import { useState } from "react";

import { Modal } from "bootstrap/dist/js/bootstrap.esm.min";

import LoadingButton from "../../components/LoadingButton";

import successIcon from "../../../img/check_tick.png";

import styles from "./Workplace.module.css"

function AddButton({ id, createTaskHandler }) {
    const [ isLoadingCreateTaskButton, setIsLoadingCreateTaskButton ] = useState(false);

    const [ newTaskTitle, setNewTaskTitle ] = useState("");
    const [ newTaskDescription, setNewTaskDescription ] = useState("");
    const [ newTaskVisibility, setNewTaskVisibility ] = useState(true);

    const handleCreateTaskButtonClick = async () => {
        setIsLoadingCreateTaskButton(true);

        //@TODO: Error-handling
        await createTaskHandler(id, newTaskTitle, newTaskDescription, newTaskVisibility);

        const closeButtonCreateTask = document.getElementById(`buttonCloseCreateTask-${ id }`);
        closeButtonCreateTask.click();

        //Open the success-modal
        const modalCreateTaskSuccess = new Modal(document.getElementById(`modalCreateTaskSuccess-${ id }`));
        modalCreateTaskSuccess.show();

        //Wait till modal transmission is completed
        setTimeout(() => setIsLoadingCreateTaskButton(false), 200);

        setNewTaskTitle("");
        setNewTaskDescription("");
        setNewTaskVisibility(true);
    }

    return (
        <>
            <div className="d-flex justify-content-end px-3 py-3">
                {/*<button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target={ `#modalNewTask-${ id }` }>*/}
                    { /*@TODO: Button soll ein Kreis mit einem + sein (Schriftfarbe weiß?) */ }
                    <i className={ `fa-solid fa-circle-plus ${ styles['btn-add-task'] }` } data-bs-toggle="modal" data-bs-target={ `#modalNewTask-${ id }` }></i>
                {/*</button>  */}
            
                { /*@TODO: Modal soll es nur einmal geben und die parentStoryId wird übergeben */ }
                <div className="modal fade" id={ `modalNewTask-${ id }` } tabIndex="-1" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header border-0">
                                <h1 className="modal-title fs-5">
                                    Neue Unteraufgabe
                                </h1>
                                <button type="button" id={ `buttonCloseCreateTask-${ id }` } className="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close"></button>
                                
                            </div>

                            <div className="modal-body py-0">
                                <div className="d-flex w-100">
                                    <input 
                                        data-bs-theme="dark"
                                        type="text"
                                        className="flex-fill"
                                        placeholder="Titel"
                                        value={ newTaskTitle }
                                        onChange={ event => setNewTaskTitle(event.target.value) } />
                                    <div className="form-check form-switch mt-2 ms-2">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            role="switch"
                                            checked={ newTaskVisibility } 
                                            onChange={ () => setNewTaskVisibility(currentNewTaskVisibility => !currentNewTaskVisibility) } />
                                    </div>
                                </div>
                                <textarea
                                    data-bs-theme="dark"
                                    className={ `${ styles['textarea-task-description'] } w-100 mt-2` }
                                    rows="7"
                                    placeholder="Beschreibung"
                                    value={ newTaskDescription }
                                    onChange={ event => setNewTaskDescription(event.target.value) } />
                            </div>
                            
                            <div className="modal-footer border-0 pt-0">
                                { /*@TODO: Wenn man auf Abbrechen klickt, sollen die Textfelder zurückgesetzt werden */ }
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Abbrechen</button>
                                { /*@TODO: Erstellen-Button soll ein LoadingButton sein*/}
                                <LoadingButton
                                    className="btn btn-primary"
                                    text="Erstellen"
                                    loadingText="Erstellen..."
                                    isLoading={ isLoadingCreateTaskButton }
                                    onClick={ handleCreateTaskButtonClick } />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id={ `modalCreateTaskSuccess-${ id }` } tabIndex="-1">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header border-0">
                            <h1 className="modal-title fs-5">
                                { newTaskTitle }
                            </h1>
                        </div>

                        <div className="modal-body d-flex align-items-center p-0">
                            <img src={ successIcon } className={ `${ styles['icon-deleteTaskStatus'] } mx-4` } alt="task icon" />
                            <div>Die Aufgabe wurde erfolgreich erstellt</div>
                        </div>

                        <div className="modal-footer border-0 pt-0">
                            <button type="button" className="btn btn-primary" data-bs-dismiss="modal">Schließen</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AddButton;