import styles from "./AuthenticatedArea.module.css"

function Footer() {
    const year = new Date().getFullYear();

    return (
        <section className={ `d-flex justify-content-center bg-primary ${styles['font-footer']}` }>
            <p>©️ Copyright {year}</p>
        </section>
    );
}

export default Footer;