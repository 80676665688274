import { useState } from "react";
import { useLoaderData } from "react-router-dom";

import CRUDfunctions from "../../lib/CRUDbackend";

import styles from "../sites/Activities/Activities.module.css";

function Management({ setLoadingState }) {
    const managementData = useLoaderData();

    const title = "Management";

    const meetupOrganizers = [ "Alle", ...managementData.organizers ];

    const [ meetupDisplay, setMeetupDisplay ] = useState(
        (managementData.meetupDisplay === "All") ?
            "Alle" :
            managementData.meetupDisplay
    );

    const updateMeetupDisplayHandler = async (_organizer) => {
        //@TODO: durch setLoadingState wird die gesamte Seite aktualisiert => 5 Backend-Aufrufe
        //      Wenn das aber nicht mehr gemacht wird, muss 1x die Seite aktualisiert werden, weil sich das Guthaben usw. ändert
        //      => die Route meetup_display muss fullSiteData zurückgeben (und Management muss einen Handler dafür mitbekommen)
        setLoadingState(true);

        const organizer = (_organizer === "Alle") ? "All" : _organizer;
        
        //Neuen organizer an Backend senden
        const meetupDisplayData = {
            organizer: { decrypted: true, data: organizer }
        };

        const updatedMeetupDisplay = await CRUDfunctions.update("/management/meetup_display/", meetupDisplayData);

        setMeetupDisplay(organizer);

        setLoadingState(false);
    }

    return (
        <>
            <h2 className="d-flex justify-content-center w-100">
                { title }
            </h2>

            <div className="accordion py-3">
                <div className="accordion-item">
                    <h2 className="accordion-header">
                        <div className="accordion-button">
                            <div className={ `d-flex w-100 ${ styles['headline-week'] }` }>
                                Treffen anzeigen
                            </div>
                        </div>
                    </h2>

                    <div className="accordion-collapse collapse show">
                        <div className="accordion-body py-3 ps-4">
                            Wähle aus, für wen Treffen angezeigt werden sollen:
                            <div className="pt-2 ps-3">
                                { meetupOrganizers.map( (organizer, index) => {
                                    return <RadioButton
                                        key={ `meetupOrganizers${ index }` }
                                        setName="meetupOrganizers"
                                        id={ `meetupOrganizers${ index }` }
                                        label={ organizer }
                                        currentSelectedLabel={ meetupDisplay }
                                        changeSelectionHandler={ updateMeetupDisplayHandler } />;
                                }) }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="accordion py-3">
                <div className="accordion-item">
                    <h2 className="accordion-header">
                        <div className="accordion-button">
                            <div className={ `d-flex w-100 ${ styles['headline-week'] }` }>
                                Neuen User erstellen
                            </div>
                        </div>
                    </h2>

                    <div className="accordion-collapse collapse show">
                        <div className="accordion-body">
                            <div className="px-2 py-2">
                                Username: <br />
                                Passwort: <br />
                                Rolle: 100 oder 101 oder 102
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

function RadioButton({ setName, id, label, currentSelectedLabel, changeSelectionHandler }) {
    return (
        <div className="form-check">
            <input onClick={ () => changeSelectionHandler(label) } className="form-check-input" type="radio" name={ setName } id={ id } defaultChecked={ (label === currentSelectedLabel) } />
            <label onClick={ () => changeSelectionHandler(label) } className="form-check-label" htmlFor={ id }>
                { label }
            </label>
        </div>
    );
}

export default Management;