import Balance from "../components/Balance";
import BCoins from "../components/BCoins";

import styles from "./AuthenticatedArea.module.css";

function Userstats({ balance, bCoins }) {
    const userrole = localStorage.userrole;

    /*const firstLabel = (userrole === '100') ? "Guthaben" : "Punktepool";
    const firstValue = (userrole === '100') ? `${ balance.toFixed(2) }€` : `${ collectablePoints }P`;

    const secondLabel = (userrole === '100') ? "Punktepool" : "Guthaben";
    const secondValue = (userrole === '100') ? collectablePoints : `${ balance.toFixed(2) }€`;*/

    return (
        <div className="d-flex w-100 justify-content-center">
            <div className={ `d-flex w-100 justify-content-around ${ styles['font-userstats'] } mt-2` }>
                {/* <StatElement label={ firstLabel } value={ firstValue }/> */}
                <BCoins value={ bCoins } />

                <Balance balance={ balance } />
                {/* <StatElement label={ secondLabel } value={ secondValue }/> */}
            </div>
        </div>
    );
}

function StatElement({ label, value}) {
    return (
        <div className="row">
            <div className="col-9 d-flex justify-content-end pt-1 px-0">{ `${ label }:` }</div>
            <div className="col-3 d-flex justify-content-end pt-1">{ value }</div>
        </div>
    );
}

export default Userstats;