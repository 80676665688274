import Card from "react-bootstrap/Card";

function ContentSection({ title, children: sectionContent }) {
    return (
        <>
            <Card.Subtitle className="mt-4">
                { title }
            </Card.Subtitle>

            { sectionContent }
        </>
    )
}

export default ContentSection;