import { useEffect, useState } from "react";

import { EventResponseButton } from "./EventResponseModal";

//@TODO: Modal-Styles sollten aus Modals.module.css kommen
import styles from "../../sites/Workplace/Workplace.module.css";

function EditTopicButton({ id, title, description }) {
    return (
        <button
            className="btn btn-outline-secondary mt-3"
            data-bs-toggle="modal"
            data-bs-target="#EditTopicModal"
            data-bs-modaltopicid={ id }
            data-bs-modaltopictitle={ title }
            data-bs-modaltopicdescription={ description }>
            Bearbeiten
        </button>
    )
}

function EditTopicModal({ updateTopicHandler }) {
    const [ id, setId ] = useState(-1);
    const [ editedTopicTitle, setEditedTopicTitle ] = useState("");
    //useEffect(() => setEditedTaskTitle(title), [ title ]);
    const [ editedTopicDescription, setEditedTopicDescription ] = useState("");
    //useEffect(() => setEditedTaskDescription(description), [ description ]);

    useEffect(() => {
        const EditTopicModal = document.getElementById("EditTopicModal");

        EditTopicModal.addEventListener('show.bs.modal', event => {
            const button = event.relatedTarget;
            setId(parseInt(button.getAttribute("data-bs-modaltopicid")));
            setEditedTopicTitle(button.getAttribute("data-bs-modaltopictitle"));
            setEditedTopicDescription(button.getAttribute("data-bs-modaltopicdescription"));
        });
    }, []);

    return (
        <div className="modal fade" id="EditTopicModal" tabIndex="-1">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="align-items-start modal-header border-0">
                        <div className="d-flex flex-column">
                            <h4 className="modal-title">
                                Aufgabenblock bearbeiten
                            </h4>
                        </div>

                        { /* @TODO: btn-close-white sollte nicht hier gesetzt werden sondern abhängig von Primary-Color und dark-Mode in SASS */ }
                        <button
                            type="button"
                            className="ms-auto btn-close btn-close-white"
                            data-bs-dismiss="modal" />
                    </div>

                    <div className="modal-body pb-1">
                        <input 
                            data-bs-theme="dark"
                            type="text"
                            className="w-100"
                            value={ editedTopicTitle }
                            onChange={ event => setEditedTopicTitle(event.target.value) } />
                        <textarea
                            data-bs-theme="dark"
                            className={ `${ styles['textarea-task-description'] } w-100 mt-2` }
                            rows="7"
                            value={ editedTopicDescription }
                            onChange={ event => setEditedTopicDescription(event.target.value) } />
                    </div>
                    
                    <div className="modal-footer border-0 pt-0">
                        <EventResponseButton
                            className="btn btn-primary"
                            text="Speichern"
                            loadingText="Speichern..."
                            modalSuccessTitle={ editedTopicTitle }
                            modalSuccessContent="Der Aufgabenblock wurde erfolgreich aktualisiert"
                            event={ () => updateTopicHandler(id, editedTopicTitle, editedTopicDescription) } />
                    </div>                    
                </div>
            </div>
        </div>
    );
}

export {
    EditTopicButton,
    EditTopicModal
}