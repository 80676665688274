import { useEffect, useState } from "react";

import {
  createBrowserRouter,
  redirect,
  RouterProvider
} from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
//import "bootstrap/dist/js/bootstrap.bundle.min";
//@TODO: Was genau ist die ESM-Datei? Wenn man bundle.min einbindet, funktionieren die Backdrops nicht richtig
//        => Wenn man einen Task öffnet, auf Löschen klickt (es öffnet sich ein zweites Modal) und dann auf Abbrechen
//            bleibt ein Backdrop
import "bootstrap/dist/js/bootstrap.esm.min";
import "./custom.scss";

import CRUDbackend from "./lib/CRUDbackend";

import Login from "./Login";

import AuthenticatedArea  from "./authenticated/AuthenticatedArea";
import Logout             from "./authenticated/sites/Logout";
import Activities from "./authenticated/sites/Activities";
import Meetups from "./authenticated/sites/Meetups";
import Rewards            from "./authenticated/sites/Rewards";
import Settings           from "./authenticated/sites/Settings";
import Workouts from "./authenticated/sites/Workouts";
import Workplace     from "./authenticated/sites/Workplace";

import Management from "./authenticated/Management/Management";

import "./styles.css";

/********************************************
 * Begin: DEBUG-Imports
 ********************************************/
import { useLoaderData } from "react-router-dom";
/*import { encryptDataObject } from "./lib/Encryption";
import { click } from "@testing-library/user-event/dist/click";
//import Story from "./authenticated/sites/Workplace/Story";
import { EventResponseButton, SuccessModal } from "./authenticated/components/modals/EventResponseModal";
import { EventConfirmationButton, EventConfirmationModal } from "./authenticated/components/modals/EventConfirmationModal";*/
/********************************************
 * End: DEBUG-Imports
 ********************************************/

function App() {
  const [ authenticatedState, setAuthenticatedState ] = useState(true);
  const [ loadingState, setLoadingState ] = useState(false);
  const [ userStats, setUserStats ] = useState({
    balance: 0,
    bCoins: 0
  });

  //@TODO: Kann der Colormode in  AuthenticatedArea verschoben werden? Dann wäre auch loadingState dort definiert
  //      Und setLoadingState würde an jedes Outlet oder Children übergeben werden
  //    Problem hier: wenn setLoadingState aufgerufen wird, wird App neu aufgebaut => neuer Request für die ganze Seite an das Backend
  //      Reproduzierbar: In Management wird setLoadingstate 2x aufgerufen, wenn man meetup_display ändert => dadurch 2 Request im Backend
  //                      Kommentiert man die setLoadingStates aus, sind auch die Requests im Backend weg
  const updateColorModeHandler = async (colorMode) => {
    const currentColormode = document.documentElement.getAttribute('data-bs-theme');
    
    if (colorMode !== currentColormode) {
      //Speichere die Änderung in der Datenbank
      setLoadingState(true);

      const updatedColorModeObj = await CRUDbackend.update("/settings/colormode", { colorMode });
      document.documentElement.setAttribute('data-bs-theme', updatedColorModeObj.colorMode);

      setLoadingState(false);
    }
  }
  
  const loadFullSiteData = async (route) => {
    if (!authenticatedState) {
      return null;
    } else {
      const backendData = await CRUDbackend.read(route);

      if (backendData.error) {
        if (backendData.error === "NOT_LOGGED_IN") {
          setAuthenticatedState(false);
        }
        return backendData;
      }

      //Structural error-handling
      if (!("colorMode" in backendData) || !("userStats" in backendData) || !("siteData" in backendData)) {

        console.log("App|loadFullSiteData: backendData hat nicht die richtige Struktur, um FullSite zu laden"); //@TODO: Error-Codes einführen
        //return { error: "Fehlermeldung" } //Error-Handling in AuthenticatedArea
      }

      if (!("balance" in backendData.userStats) ||
          !("bCoins" in backendData.userStats)) {

        console.log("App|loadFullSiteData: backendData.userStats hat nicht die richtige Struktur, um FullSite zu laden"); //@TODO: Error-Codes einführen
        //return { error: "Fehlermeldung" } //Error-Handling in AuthenticatedArea
      }

      document.documentElement.setAttribute('data-bs-theme', backendData.colorMode);

      //Hier wird nicht die Set-Methode für userStats verwendet
      //Würde man sie verwenden, würde die Seite neu gerendert werden und man würde wieder hier landen
      //  => Endlosschleife
      //Die Funktion loadFullSiteData wird immer im loader aufgerufen, d.h. die Seite wurde noch nicht
      //gerendert. Deshalb kann hier der Initialwert ohne Set-Methode gesetzt werden
      //Sollte man die Userstats auf einer Seite z.B. durch einen Aktualisieren-Button neu laden wollen,
      //muss es dafür eine eigene Funktion geben, die die userstats vom Server lädt und dann die
      //Set-Methode verwendet
      userStats.balance = backendData.userStats.balance;
      userStats.bCoins = backendData.userStats.bCoins;
      
      return backendData.siteData;
    }
  }

  const reloadUserStatsHandler = async () => {
    const userStats = await CRUDbackend.read("/general/userstats");

    if (!("balance" in userStats) ||
        !("bCoins" in userStats)) {

        console.log("App|reloadUserStats: userStats hat nach Reload nicht die richtige Struktur"); //@TODO: Error-Codes einführen
        //return { error: "Fehlermeldung" } //Error-Handling in AuthenticatedArea
    }

    setUserStats(userStats);
  }

  const router = createBrowserRouter([
    {
      path: '/debug',
      element: <Debug />,
      loader: () => CRUDbackend.read("/general/progress/next_activity?owner=Selina&messageType=Uncategorised")
    },
    {
      path: '',
      loader: () => redirect('/login')
    },
    {
      path: '/login',
      element: <Login loginHandler={ () => setAuthenticatedState(true) } />
    },
    {
      path: '/',
      element: <AuthenticatedArea
                  loadingState={ loadingState }
                  userStats={ userStats } />,
      loader: () => authenticatedState, //getNaviLinks
      children: [
        /*{
          path: 'rewards',
          children: [
            {
              path: '',
              element: <Rewards />,
              loader: () => { 
                return {
                  bonuscode: null,
                  siteData: loadFullSiteData("/rewards/")
                }
              }
            },
            {
              path: ':bonuscode',
              element: <Rewards />,
              loader: ({ params }) => { 
                return {
                  bonuscode: params.bonuscode,
                  siteData: loadFullSiteData("/rewards/")
                }
              }
            }
          ]
        },*/
        {
          path: 'rewards',
          element: <Rewards />,
          loader: () => loadFullSiteData("/rewards")
        },
        {
          path: 'meetups',
          element: <Meetups />,
          loader: () => loadFullSiteData("/rewards/meetups")
        },
        {
          path: 'activities',
          element: <Activities />,
          loader: () => loadFullSiteData("/activities")
        },
        {
          path: 'workplace',
          element: <Workplace />,
          loader: () => loadFullSiteData("/workplace")
        },
        {
          path: 'workouts',
          element: <Workouts reloadUserStatsHandler={ reloadUserStatsHandler } />,
          loader: () => loadFullSiteData("/workouts")
        },
        {
          path: 'settings',
          element: <Settings updateColorModeHandler={ updateColorModeHandler } />,
          loader: () => loadFullSiteData("/settings")
        },
        {
          path: 'logout',
          element: <Logout />
        },
        {
          path: 'management',
          element: <Management 
                      setLoadingState={ setLoadingState } />,
          loader: () => loadFullSiteData("/management")
        }
      ]
    }
  ]);

  return (
      <div className="app-body">
        <RouterProvider router={ router } />
      </div>
  );
}

function Debug() {
}

export default App;
