import instructionPointsIcon from "../../../img/instructionPoints.png";

import styles from "./Workplace.module.css";

function InstructionPoints({ points, className, scaling }) {
    return (
        <div className={ `d-flex ${ (className) && className }` } style={{ transform: `scale(${ (scaling) ? scaling : 1 })` }}>
            <div>
                <img src={ instructionPointsIcon } className={ `${ styles['icon-instructionPoints'] } pe-1` } alt="instruction points icon" />
            </div>

            <div className={ `${ styles['font-instructionPoints'] }` } >{ points }</div>
        </div>
    );
}

export default InstructionPoints;