import { 
    useEffect,
    useState
} from "react";

import {
    useLocation,
    useNavigate
} from "react-router-dom";

import {
    decryptAES,
    hashSHA512
} from "./lib/Encryption";

import "./Login.css";

function Login({ loginHandler }) {
    const navigate = useNavigate();

    const { state: locationState } = useLocation();
    //Nach redirect aus App.js existiert das locationState-Objekt hat aber nur _isRedirect als Inhalt
    const redirectAfterLogin = (locationState && ('location' in locationState)) ? locationState.location.pathname : "/activities";

    //Wird nur zu Beginn beim Seitenaufruf ausgeführt und wird gebraucht, dass man weitergeleitet wird, wenn man schon eingeloggt ist
    useEffect(() => {
        if (localStorage.token) {
            navigate(redirectAfterLogin);
        }
    }, [ navigate, redirectAfterLogin ]);

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [loginError, setLoginError] = useState("");

    const onSubmitForm = async e => {
        e.preventDefault();

        setLoginError("");
        try {
            const body = { username, password };
            
            const response = await fetch(process.env.REACT_APP_BACKEND_URL + "/login", {
                method: "POST",
                headers: {
                    "Content-type": "application/json"
                },
                body: JSON.stringify(body)
            });

            const loginResult = await response.json();
            if (loginResult.token) {
                localStorage.setItem("token", loginResult.token);
                localStorage.setItem("username", username);
                localStorage.setItem("userrole", loginResult.userrole);

                const encryptionKey = decryptAES(loginResult.userspecific_key, hashSHA512(password));
                localStorage.setItem("dataKey", encryptionKey);
            
                loginHandler();

                navigate(redirectAfterLogin);
            } else {
                if (loginResult.error) {
                    switch (loginResult.error) {
                        case "USER_NOT_FOUND":
                            setLoginError("Der Username existiert nicht");
                            break;
                        case "INVALID_PASSWORD":
                            setLoginError("Ungültiges Passwort");
                            break;
                        default: setLoginError("Ein Fehler ist aufgetreten");
                    }
                } else {
                    setLoginError("Ein Fehler ist aufgetreten");
                } 
            }
        } catch (err) {
            console.error(err.message);
        }
    }

    //@TODO: login form shows no suggestions for username if textfield is clicked 
    return (
        <div className="Login d-flex justify-content-center row">
            <form className="col-xxl-2 col-xl-3 col-md-4 col-8" onSubmit={ onSubmitForm }>
                <input type="text" className="form-control input-login" placeholder="Username" value={ username } onChange={ e => setUsername(e.target.value) }/>
                <input type="password" className="form-control input-login" placeholder="Passwort" value={ password } onChange={ e => setPassword(e.target.value) }/>
                <button type="submit" className="form-control input-login btn-login" onClick={ event => event.target.blur() }>LOGIN</button>
                <div className="login-error" name="login-error">{ loginError }</div>
            </form>
        </div>
    );
}

export default Login;