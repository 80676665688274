import { useEffect, useState } from "react";
import { useLoaderData } from "react-router-dom";

import CRUDfunctions from "../../lib/CRUDbackend";

import BarElementx1 from "../components/BarElementx1";

import Activity from "./Activities/Activity";
import Balance from "../components/Balance";
import BCoins from "../components/BCoins";

import { Tooltip } from "bootstrap/dist/js/bootstrap.esm.min";

import textIcon from "../../img/activity_text.png";
import voiceIcon from "../../img/activity_voice.png";
import imageIcon from "../../img/activity_image.png";

import styles from "./Activities/Activities.module.css";
import styles2 from "./Workplace/Workplace.module.css";

function Activities() {
    const userrole = parseInt(localStorage.userrole);

    const activitiesData = useLoaderData();

    const title = "Online-Aktivitäten";

    const activities = activitiesData.activities;
    const nextActivitiesData = activitiesData.nextActivitiesData;
    const activitiesPeriod = activitiesData.activitiesPeriod;

    ///////////////////////////////////////////////////////////////////////////////////////////////
    //@TODO: muss in eine utility-Funktion (wird auch in Activity verwendet)
    const startDate = new Date(activitiesPeriod.start);
    const startDay = `${ (startDate.getDate() < 10) ? "0" : "" }${ startDate.getDate() }`;
    const startMonth = `${ (startDate.getMonth() < 9) ? "0" : "" }${ startDate.getMonth() + 1 }`;
    const startYear = `${ startDate.getFullYear() }`;
    const activitiesPeriodStart = `${ startDay }.${ startMonth }.${ startYear }`;

    const endDate = new Date(activitiesPeriod.end);
    const endDay = `${ (endDate.getDate() < 10) ? "0" : "" }${ endDate.getDate() }`;
    const endMonth = `${ (endDate.getMonth() < 9) ? "0" : "" }${ endDate.getMonth() + 1 }`;
    const endYear = `${ endDate.getFullYear() }`;
    const activitiesPeriodEnd = `${ endDay }.${ endMonth }.${ endYear }`;
    ///////////////////////////////////////////////////////////////////////////////////////////////

    //@TODO: nextActivityData sollte ein JSON-Objekt sein, mit owner als key => kein Array; so dass man nextActivityData[0].owner ersetzen kann
    return (
        <>
            <h2 className="d-flex justify-content-center w-100">
                { title }
            </h2>
            
            { nextActivitiesData.map( nextActivitiesOwnerData => {
                return (
                    <div key={ nextActivitiesOwnerData[0].owner } className="accordion py-3">
                        <div className="accordion-item">
                            <h2 className="accordion-header">
                                { (nextActivitiesData.length > 1) &&
                                    <div className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={ `#nextActivities-${ nextActivitiesOwnerData[0].owner }` }>
                                        { `${ nextActivitiesOwnerData[0].owner }'s nächste Aktivitäten` }
                                    </div>
                                }
                                { (nextActivitiesData.length === 1) &&
                                    <div className="accordion-button">
                                        Nächste Aktivitäten
                                    </div>
                                }
                            </h2>

                            <div className={ `accordion-collapse collapse ${ (nextActivitiesData.length === 1) && "show" }` } id={ `nextActivities-${ nextActivitiesOwnerData[0].owner }` }>
                                <div className="accordion-body">
                                    { (nextActivitiesData.length === 1) &&
                                        <div>
                                            <div className="px-3 pt-3">
                                                Du kassierst bCoins für jede deiner Aktivitäten, falls welche verfügbar sind.
                                                Wie viele bCoins du für das nächste Foto erhältst, siehst du in dieser Übersicht.
                                                Sobald du bCoins einkassierst, werden sie in Guthaben umgewandelt
                                            </div>
                                            <div className={ `d-flex flex-column align-items-center ${ styles2['instruction-bonuslist-emphasis'] } py-3` }>
                                                <div>Umwandlungsrate</div>
                                                <div className="d-flex align-items-center">
                                                    <BCoins className="px-2 py-2" value={ 100 } /> = <Balance className="px-2 py-2" balance={ 0.1 } />
                                                </div>
                                            </div>
                                        </div>
                                    }

                                    <div>
                                        { nextActivitiesOwnerData.map( nextActivityData => {
                                            return <NextActivityBar
                                                key={ nextActivityData.messageType }
                                                type={ nextActivityData.messageType }
                                                owner={ nextActivityData.owner }
                                                bCoinsMax={ nextActivityData.bCoinsMax }
                                                _data={{
                                                    bCoins: nextActivityData.bCoins,
                                                    validUntil: nextActivityData.validUntil
                                                }} />
                                        } ) }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            } ) }

            <div className="accordion py-3">
                <div className="accordion-item">
                    <h2 className="accordion-header">
                        <div className="accordion-button">
                            <div className={ `d-flex w-100 justify-content-center ${ styles['headline-week'] }` }>
                                { activitiesPeriodStart } - { activitiesPeriodEnd }
                            </div>
                        </div>
                    </h2>

                    <div className="accordion-collapse collapse show">
                        <div className="accordion-body">                            
                            <div>
                                { activities.map( activity => {
                                    return <Activity
                                                key={ activity.id }
                                                owner={ activity.owner }
                                                type={ activity.messageType }
                                                bCoins={ activity.bCoins }
                                                timestamp={ activity.timestamp } />
                                }) }

                                { (activities.length === 0) &&
                                    <div className="px-2 py-2">
                                        { (userrole === 100) &&
                                            "Du hast diese Woche noch keine Fotos gesendet..."
                                        }
                                        { (userrole !== 100) &&
                                            "Diese Woche wurden noch keine Fotos gesendet..."
                                        }
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

function NextActivityBar({ type, owner, bCoinsMax, _data }) {
    const [ data, setData ] = useState({
        bCoins: _data.bCoins,
        validUntil: _data.validUntil
    });

    const updateData = async () => {
        const inputValues = `owner=${ owner }&messageType=${ type }`;
        const updatedData = await CRUDfunctions.read(`/general/progress/next_activity?${ inputValues }`);

        setData({
            bCoins: updatedData.value,
            validUntil: updatedData.validUntil
        });
    }

    useEffect( () => {
        setTimeout( () => {
            updateData();
        }, Math.max(0, new Date(data.validUntil).getTime() - (new Date()).getTime())) ;
    }, [ data.validUntil ]);

    const title = ( () => {
        switch (type) {
            case "Text":
                return "Textnachricht";
            case "Voice":
                return "Sprachnachricht";
            default: //"Image", "Image_object", "Uncategorised":
                return "Foto";
        }
    } )();

    const icon = ( () => {
        switch (type) {
            case "Text":
                return textIcon;
            case "Voice":
                return voiceIcon;
            default: //"Image", "Image_object", "Uncategorised":
                return imageIcon;
        }
    })();

    return <BarElementx1
            title={ title }
            subtitle={ owner } 
            pointsElement={
                <BCoins value={ data.bCoins } />
            }
            progressStatus={ (data.bCoins / bCoinsMax) * 100 }
            icon={ icon }/>
}

export default Activities;