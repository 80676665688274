import { createPortal } from "react-dom";
import { useEffect, useState } from "react";
import MeetupData from "../../sites/Meetups/MeetupData";

import BarElementx1 from "../BarElementx1";
import Balance from "../Balance";

//@TODO: Sollte ein normales Modal mit Schließen-Button sein
// Verschiedene Modi:
//  prop: component="Meetup"/"Text" usw.
//  nur ein Datenfeld, dem ein JSON-Objekt mit JSON.Stringify übergeben wird
//  Im Modal-Body switch-case, dass je nach component das Richtige macht

// z.b. im Button: data-bs-component="Text" data-bs-componentData={ JSON.stringify( { text: "Text zum Anzeigen" } ) }
//      im Modal-Body: switch(data-bs-component):
//                          case "Text": unstringify(data-bs-componentData).text;
function Button({ timestamp, reward, bCoinsFromReward, cashoutFromReward, cashoutFromBalance, totalCashout, comment, organizer }) {
    const dateTimestamp = new Date(timestamp);
    const day = `${ (dateTimestamp.getDate() < 10) ? "0" : "" }${ dateTimestamp.getDate() }`;
    const month = `${ (dateTimestamp.getMonth() < 9) ? "0" : "" }${ dateTimestamp.getMonth() + 1 }`;
    const year = `${ dateTimestamp.getFullYear() }`;

    const dateString = `${ day }.${ month }.${ year }`;

    return (
        <div
            role="button"
            data-bs-dismiss="modal"
            data-bs-toggle="modal"
            data-bs-target="#MeetUpModal"
            data-bs-meetuptimestamp={ timestamp.toString() }
            data-bs-meetupreward={ reward }
            data-bs-meetupbcoinsfromreward={ bCoinsFromReward }
            data-bs-meetupcashoutfromreward={ cashoutFromReward }
            data-bs-meetupcashoutfrombalance={ cashoutFromBalance }
            data-bs-meetuptotalcashout={ totalCashout }
            data-bs-meetupcomment={ comment }
            data-bs-meetuporganizer={ organizer }>
                <BarElementx1
                    title={ reward }
                    subtitle={ `Treffen vom ${ dateString } ${ (organizer !== "") ? `mit ${ organizer }` : ""}` } 
                    pointsElement={
                        <Balance balance={ totalCashout } />
                    }
                    progressStatus={ 100 } />
        </div>
    );
}

function Modal() {
    const [ timestampDate, setTimestampDate ] = useState(new Date());
    const [ reward, setReward ] = useState("");
    const [ bCoinsFromReward, setBCoinsFromReward ] = useState(0);
    const [ cashoutFromReward, setCashoutFromReward ] = useState(0.00);
    const [ cashoutFromBalance, setCashoutFromBalance ] = useState(0.00);
    const [ totalCashout, setTotalCashout ] = useState(0.00);
    const [ comment, setComment ] = useState("");
    const [ organizer, setOrganizer ] = useState("");

    useEffect(() => {
        const EventConfirmationModal = document.getElementById("MeetUpModal");

        EventConfirmationModal.addEventListener('show.bs.modal', event => {
            // Button that triggered the modal
            const button = event.relatedTarget
            setTimestampDate(new Date(button.getAttribute("data-bs-meetuptimestamp")));
            setReward(button.getAttribute("data-bs-meetupreward"));
            setBCoinsFromReward(parseInt(button.getAttribute("data-bs-meetupbcoinsfromreward")));
            setCashoutFromReward(parseFloat(button.getAttribute("data-bs-meetupcashoutfromreward")));
            setCashoutFromBalance(parseFloat(button.getAttribute("data-bs-meetupcashoutfrombalance")));
            setTotalCashout(parseFloat(button.getAttribute("data-bs-meetuptotalcashout")));
            setComment(button.getAttribute("data-bs-meetupcomment"));
            setOrganizer(button.getAttribute("data-bs-meetuporganizer"));
          })
    }, []);

    const dateTimestamp = new Date(timestampDate);
    const day = `${ (dateTimestamp.getDate() < 10) ? "0" : "" }${ dateTimestamp.getDate() }`;
    const month = `${ (dateTimestamp.getMonth() < 9) ? "0" : "" }${ dateTimestamp.getMonth() + 1 }`;
    const year = `${ dateTimestamp.getFullYear() }`;

    const dateString = `${ day }.${ month }.${ year }`;

    return createPortal(
        <>
            <div className="modal fade" id="MeetUpModal" tabIndex="-1">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header border-0">
                            <h1 id="successModal-title" className="modal-title fs-5">
                                Treffen vom { dateString } { (organizer !== "") ? `mit ${ organizer }` : ""}
                            </h1>
                        </div>

                        <div className="modal-body d-flex align-items-center">
                            <MeetupData
                                timestamp={ timestampDate } 
                                reward={ reward }
                                isCompleted={ true }
                                bCoinsFromReward={ bCoinsFromReward }
                                cashoutFromReward={ cashoutFromReward }
                                cashoutFromBalance={ cashoutFromBalance }
                                totalCashout={ totalCashout }
                                comment={ comment } />
                        </div>

                        <div className="modal-footer border-0 pt-0">
                            <button type="button" className="btn btn-primary" data-bs-dismiss="modal">Schließen</button>
                        </div>
                    </div>
                </div>
            </div>    
        </>,
        document.body
    );
}

const MeetupModal = {
    Modal,
    Button
};

export default MeetupModal;