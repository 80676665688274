
import { useEffect, useState } from "react";

import InstructionPoints from "../../sites/Workplace/InstructionPoints";
import TaskDetailsTab from "../../sites/Workplace/TaskDetailsTab";
import TaskEditorTab from "../../sites/Workplace/TaskEditorTab";
import { EventResponseButton } from "../../components/modals/EventResponseModal";
import { EventConfirmationButton, EventConfirmationModal } from "../../components/modals/EventConfirmationModal";
import { SuccessModal } from "../../components/modals/EventResponseModal";

import finishedIcon from "../../../img/check_tick.png";
import taskIcon from "../../../img/task.png";
import invisibleIcon from "../../../img/invisible.png";

//@TODO: Modal-Styles sollten aus Modals.module.css kommen
import styles from "../../sites/Workplace/Workplace.module.css";

function TaskButton({ id, title, description, isPlanned, points, processingStatus, visibility }) {
    return (
        <>
            <div
                className={ `d-flex w-100 ${ ((isPlanned) && (processingStatus !== 102)) ? styles['task-btn-planned'] : styles['task-btn'] }` } 
                data-bs-toggle="modal"
                data-bs-target="#TaskModal"
                data-bs-modaltaskid={ id }
                data-bs-modaltasktitle={ title }
                data-bs-modaltaskdescription={ description }
                data-bs-modaltaskprocessingstatus={ processingStatus }
                data-bs-modaltaskisplanned={ isPlanned }
                data-bs-modaltaskpoints={ points }
                data-bs-modaltaskvisibility={ visibility }>

                <div className="d-flex align-items-center pe-3">
                    { (!visibility) &&
                        <img src={ invisibleIcon } className={ `${ styles['icon-task'] }` } alt="invisible icon" />
                    }

                    { (visibility) &&
                        <>
                            { (processingStatus !== 102) &&
                                <img src={ taskIcon } className={ `${ styles['icon-task'] }` } alt="task icon" />
                            }
                            { (processingStatus === 102) &&
                                <img src={ finishedIcon } className={ `${ styles['icon-task'] }` } alt="finished icon" />
                            }
                        </>
                    }
                </div>

                <div className="d-flex justify-content-between w-100">
                    <div>
                        { title }
                    </div>
                    <div>
                        { (points > 0) && 
                            <InstructionPoints points={ points } />
                        } 
                    </div>
                </div>
            </div>
        </>
    );
}

function TaskModal({ instructionBalance, updateTaskHandler, updateTaskStatusHandler, updateTaskPlanningHandler, deleteTaskUIHandler, convertTaskToStoryHandler, updateElementVisibilityHandler }) {
    const userrole = parseInt(localStorage.userrole);

    const [ id, setId ] = useState(-1);
    const [ title, setTitle ] = useState("");
    const [ description, setDescription ] = useState("");
    const [ processingStatus, setProcessingStatus ] = useState("");
    const [ isPlanned, setIsPlanned ] = useState(false);
    const [ points, setPoints ] = useState(0);
    const [ visibility, setVisibility ] = useState(false);

    const [ usedPlanningPoints, setUsedPlanningPoints ] = useState(100);
    const [ usedPlanningPointsMax, setUusedPlanningPointsMax] = useState(0);

    const handleTaskVisibilityChange = async () => {
        await updateElementVisibilityHandler("task", id, !visibility);
        setVisibility( currentVisibility => !currentVisibility );
    }

    useEffect(() => {
        const TaskModal = document.getElementById("TaskModal");

        TaskModal.addEventListener('show.bs.modal', event => {
            const button = event.relatedTarget;
            setId(parseInt(button.getAttribute("data-bs-modaltaskid")));
            setTitle(button.getAttribute("data-bs-modaltasktitle"));
            setDescription(button.getAttribute("data-bs-modaltaskdescription"));
            setProcessingStatus(parseInt(button.getAttribute("data-bs-modaltaskprocessingstatus")));
            setPoints(parseInt(button.getAttribute("data-bs-modaltaskpoints")));
            setIsPlanned((button.getAttribute("data-bs-modaltaskisplanned") === "true"));
            setVisibility((button.getAttribute("data-bs-modaltaskvisibility") === "true"));

            setUsedPlanningPoints(Math.max(100, parseInt(button.getAttribute("data-bs-modaltaskpoints"))));
            setUusedPlanningPointsMax(parseInt(button.getAttribute("data-bs-modaltaskpoints")) + instructionBalance);
        });

        TaskModal.addEventListener('hidden.bs.modal', event => {
            document.getElementById(`task-details-tab`).click();
        });
    }, [ instructionBalance ]);

    return (
        <>
            <EventConfirmationModal />
            <SuccessModal />

            <div className="modal fade" id="TaskModal" tabIndex="-1">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="align-items-start modal-header border-0">
                            <div className="d-flex flex-column">
                                <h4 className="modal-title">
                                    { title }
                                </h4>

                                <div className={ `${ styles['task-modal-subtitle-points'] } mt-1` }>
                                    { (isPlanned) &&
                                        <>
                                            { (userrole === 100) &&
                                                <>Du hast diese Aufgabe mit { points }P angeordnet</>
                                            }
                                            { (userrole !== 100) &&
                                                <>Diese Aufgabe wurde mit { points }P angeordnet</>
                                            }
                                        </>
                                    }
                                    { ((!isPlanned) && (points > 0)) &&
                                        <>Diese Aufgabe zählt { points }P</>
                                    }
                                </div>
                            </div>

                            { /* @TODO: btn-close-white sollte nicht hier gesetzt werden sondern abhängig von Primary-Color und dark-Mode in SASS */ }
                            <button
                                type="button"
                                className="ms-auto btn-close btn-close-white"
                                data-bs-dismiss="modal" />
                        </div>

                        <nav className="d-flex justify-content-between">
                            <div className="nav nav-underline px-3 pb-3" id="nav-tab" role="tablist">
                                <button className="nav-link active" id="task-details-tab" data-bs-toggle="tab" data-bs-target="#task-details" type="button" role="tab">Beschreibung</button>
                                { ((userrole !== 100) && (processingStatus !== 102)) &&
                                    <button className="nav-link" id="task-editing-tab" data-bs-toggle="tab" data-bs-target="#task-editing" type="button" role="tab">Bearbeiten</button>
                                }
                                { ((userrole === 100) && (processingStatus !== 102)) &&
                                    <button className="nav-link link-warning" id="task-planning-tab" data-bs-toggle="tab" data-bs-target="#task-planning" type="button" role="tab">Anordnung</button>
                                }
                            </div>  

                            { (userrole !== 100) &&
                                <div className="form-check form-switch mt-2 pe-4">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        role="switch"
                                        checked={ visibility }
                                        disabled={ (isPlanned) || (processingStatus === 102) }
                                        onChange={ handleTaskVisibilityChange } />
                                </div>
                            }
                        </nav>
                        <div className="tab-content">
                            <div className="tab-pane fade show active" id="task-details" role="tabpanel" tabIndex="0">
                                <TaskDetailsTab
                                    id={ id }
                                    title={ title }
                                    description={ description }
                                    processingStatus={ processingStatus }
                                    deleteTaskUIHandler={ deleteTaskUIHandler }
                                    updateTaskStatusHandler={ updateTaskStatusHandler } />
                            </div>
                        </div>

                        <div className="tab-content">
                            <div className="tab-pane fade" id="task-editing" role="tabpanel" tabIndex="0">
                                <TaskEditorTab
                                    id={ id }
                                    title={ title }
                                    description={ description }
                                    updateTaskHandler={ updateTaskHandler}
                                    convertTaskToStoryHandler={ convertTaskToStoryHandler }
                                    isEditingDisabled={ isPlanned } />
                            </div>
                        </div>

                        { (true/*userrole === 100*/) &&
                            <div className="tab-content">
                                <div className="tab-pane fade px-3 pb-3" id="task-planning" role="tabpanel" tabIndex="0">
                                    {/*Du kannst die Bearbeitung dieser Aufgabe anordnen. Gib dafür einen Teil deiner Anordnungs-Punkte (mind. 100) an. Sobald die Aufgabe erledigt wurde, kassierst du die angegebene Punkteanzahl ein. Die einkassierten Punkte werden automatisch in Guthaben umgewandelt und deinem Konto gutgeschrieben.*/}
                                    <div className="mt-2 mb-4">
                                        Wähle aus, wie viele Punkte du für die Anordnung dieser Aufgabe festlegen willst:
                                    </div>
                                    <div className="d-flex flex-column align-items-center">
                                        <div className="d-flex justify-content-center align-items-center mb-2">
                                            <div onClick={ () => setUsedPlanningPoints( currentUsedPlanningPoints => Math.max(100, currentUsedPlanningPoints - 10))}>
                                                <i className={ `fa-solid fa-circle-minus ${ styles['change-planningPoints-button'] }` } />
                                            </div>
                                            <h1 className="mb-0 mx-3">{ usedPlanningPoints }</h1>
                                            <div onClick={ () => setUsedPlanningPoints( currentUsedPlanningPoints => Math.min(usedPlanningPointsMax, currentUsedPlanningPoints + 10))}>
                                                <i className={ `fa-solid fa-circle-plus ${ styles['change-planningPoints-button'] }` } />
                                            </div>
                                        </div>
                                        <input type="range" className="form-range w-75" min="100" max={ usedPlanningPointsMax } step="10" value={ usedPlanningPoints } onChange={ event => setUsedPlanningPoints( parseInt(event.target.value) ) }></input>
                                    </div>

                                    <div className={ `d-flex mt-4 ${ (isPlanned) ? "justify-content-between" : "justify-content-end" }` }>
                                        { (isPlanned) &&
                                            <EventConfirmationButton
                                                text="Anordnung aufheben"
                                                eventFunction={ async () => await updateTaskPlanningHandler(id, 0) }
                                                confirmationButtonText="Aufheben"
                                                confirmationButtonLoadingText="Aufheben..." 
                                                modalSuccessTitle={ title } 
                                                modalSuccessContent="Die Anordnung wurde aufgehoben"
                                                modalEventConfirmationTitle={ title }
                                                modalEventConfirmationContent="Soll die Anordnung der Aufgabe wirklich aufgehoben werden?" />
                                        }

                                        <EventResponseButton
                                            className="btn btn-primary"
                                            text={ (isPlanned) ? "Speichern" : "Anordnen" }
                                            loadingText="Anordnen..."
                                            modalSuccessTitle={ title }
                                            modalSuccessContent={ (isPlanned) ?
                                                                    "Die Änderung der Anordnung wurde erfolgreich gespeichert" :
                                                                    "Die Bearbeitung der Aufgabe wurde erfolgreich angeordnet" }
                                            event={ () => updateTaskPlanningHandler(id, usedPlanningPoints) } />
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </>
    );
}

export {
    TaskButton,
    TaskModal
}