import styles from "./Settings.module.css";

function ColorModeSelector({ activeColorMode, updateColorModeHandler }) {
    const colorModesData = [
        {
            name: "noblePurple",
            primaryColor: "#59359a",
            secondaryColor: "white"
        },
        {
            name: "blackNun",
            primaryColor: "#9c0500",
            secondaryColor: "black"
        },
        {
            name: "darkCurler",
            primaryColor: "#fdafad",
            secondaryColor: "#212529"
        }
    ];

    return (
        <div className="row justify-content-center">
            { colorModesData.map( colorModeData => {
                return <div key={ colorModeData.name } className={ `col-4 d-flex justify-content-center` } onClick={ () => updateColorModeHandler(colorModeData.name) }>
                            <ColorModeRepresentation
                                name={ colorModeData.name }
                                active={ (colorModeData.name === activeColorMode) }
                                primaryColor={ colorModeData.primaryColor }
                                secondaryColor={ colorModeData.secondaryColor }
                            />
                        </div>;
            })}
        </div>
    );
}

function ColorModeRepresentation({ name, active, primaryColor, secondaryColor}) {
    const cssBorderColors = {
        "borderLeftColor": primaryColor,
        "borderBottomColor": secondaryColor
    }

    return (
        <div className={`${styles['colorModeSquare-border']} ${(active) && styles['colorMode-active']}`}>
            <div role="button" className={ styles['colorModeSquare'] } style={ cssBorderColors } />
        </div>
    );
}

export default ColorModeSelector;