import { EventConfirmationButton } from "../../components/modals/EventConfirmationModal";
import { EventResponseButton } from "../../components/modals/EventResponseModal";

import finishedIcon from "../../../img/check_tick.png";
import removedIcon from "../../../img/remove.png";

import styles from "./Workplace.module.css"

function TaskDetailsTab({ id, title, description, processingStatus, deleteTaskUIHandler, updateTaskStatusHandler }) {
    const userrole = parseInt(localStorage.userrole);

    return(
        <div className="modal-body pt-0">
            { /*@TODO: mb-4 soll weg und die Buttons in den Footer (Es soll keinen Header und Footer mehr geben => Es wird eine Komponente für Details erstellt und die kommt in TaskModal in den Tab) */ }
            <div className="mb-4">
                { description }
            </div>
            
            { (userrole !== 100) &&
                <>
                    { (processingStatus !== 102) &&
                        <div className="d-flex justify-content-between">
                            <EventConfirmationButton
                                text="Löschen"
                                eventFunction={ async () => await deleteTaskUIHandler(id) }
                                confirmationButtonText="Löschen"
                                confirmationButtonLoadingText="Löschen..." 
                                modalSuccessTitle={ title } 
                                modalSuccessContent="Die Aufgabe wurde erfolgreich gelöscht"
                                modalEventConfirmationTitle={ title }
                                modalEventConfirmationContent="Soll die Aufgabe wirklich gelöscht werden?" />

                            <EventResponseButton
                                className="btn btn-primary"
                                text="Abgeschlossen"
                                loadingText="Abschließen..."
                                modalSuccessTitle={ title }
                                modalSuccessContent="Die Aufgabe wurde erfolgreich abgeschlossen"
                                event={ () => updateTaskStatusHandler(id) } />
                        </div>
                    }
                </>
            }

            { (processingStatus === 102) &&
                <div className="d-flex justify-content-end">
                    <img src={ finishedIcon } className={ styles['icon-ticketstatus'] } alt="finished icon" />
                </div>
            }

            { /*@TODO: <div>Kommentare</div> */ }
        </div>
    );  
}

export default TaskDetailsTab;