import Card from "react-bootstrap/Card";

import Content from "../AuthenticatedArea/Content";
import ContentSection from "../AuthenticatedArea/ContentSection";
import ColorModeSelector from "./Settings/ColorModeSelector";

import styles from "./Settings/Settings.module.css";

//@TODO: Get profile-pic from database (also in ../members/Profil.js)
import imgProfile_F from "../../img/profile_F.png";
import imgProfile_B from "../../img/profile_B.png";

function Settings({ colorMode, updateColorModeHandler }) {
    const title = "Einstellungen";
    
    const userrole = localStorage.userrole;
    const imgProfile = (userrole === '100') ? imgProfile_F : imgProfile_B;

    return (
        <Card>
            <Content title={ title }>
                <ContentSection title="Profilbild">
                    <div className="d-flex justify-content-center">
                        <img className={ styles['img-profil-lg'] } src={ imgProfile } alt="" />
                    </div>
                </ContentSection>

                <ContentSection title="Farbenlayout">
                    <div>Wähle die Farbpalette aus, die dir am besten gefällt:</div>
                    <ColorModeSelector activeColorMode={ colorMode } updateColorModeHandler={ updateColorModeHandler } />
                </ContentSection>

    { /*
                <ContentSection title="Kennwort ändern">
                    Um dein Kennwort zu ändern, gib dein aktuelles und ein neues ein. Danach bestätige dein neues Kennwort:
                </ContentSection>
    */ }
            </Content>
        </Card>
    );
}

export default Settings;

/*<div>
            Account
            <button className="btn btn-primary">Testbutton</button>
            <Button variant="primary">Primary</Button>
        </div>*/