import { useEffect, useState } from "react";

import { useLoaderData } from "react-router-dom";

import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import ProgressBar from "react-bootstrap/ProgressBar";

import CRUDfunctions from "../../lib/CRUDbackend";

import Content from "../AuthenticatedArea/Content";


import styles from "./Activities/Activities.module.css";

import BarElementx1 from "../components/BarElementx1";
import BCoins from "../components/BCoins";
import tripIcon from "../../img/activity_image.png";
import carIcon from "../../img/activity_image.png";
import { EventConfirmationButton, EventConfirmationModal } from "../components/modals/EventConfirmationModal_general";
import { SuccessModal } from "../components/modals/EventResponseModal";
import { click } from "@testing-library/user-event/dist/click";

function Rewards() {
    const userrole = parseInt(localStorage.userrole);

    const rewardData = useLoaderData();

    const rewards = rewardData.rewards;

    const title = "Belohnungen";

    return (
        <>
            <EventConfirmationModal />
            <SuccessModal />

            <h2 className="d-flex justify-content-center w-100">
                { title }
            </h2>

            <div className="accordion py-3">
                <div className="accordion-item">
                    <h2 className="accordion-header">
                        <div className="accordion-button" />
                    </h2>

                    <div className="accordion-collapse collapse show">
                        <div className="accordion-body">                            
                            <div>
                                { rewards.map( reward => {
                                    const organizer = (userrole === 100) ? null : reward.organizer;

                                    return <RewardBar
                                        key={ reward.id }
                                        id={ reward.id }
                                        isAddingRewardToMeetupAllowed={ (userrole === 101) }
                                        title={ reward.title }
                                        description={ reward.description }
                                        bCoins={ reward.nextCompletionData.bCoins }
                                        validUntil={ reward.nextCompletionData.validUntil }
                                        bCoinsMax={ reward.nextCompletionData.bCoinsMax }
                                        icon={ tripIcon }
                                        organizer={ organizer } />
                                }) }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

function RewardBar({ id, isAddingRewardToMeetupAllowed, title, description, bCoins, validUntil, bCoinsMax, icon, organizer }) {
    const [ nextCompletionData, setNextCompletionData ] = useState( {
        bCoins: bCoins,
        validUntil: validUntil,
        bCoinsMax: bCoinsMax
    });

    const organizerLabel = (organizer) ? `${ organizer } - ` : "";
    const subtitle = ( nextCompletionData.bCoins === bCoinsMax) ?
        `${ organizerLabel }Maximale bCoins verfügbar` :
        `${ organizerLabel }Maximale bCoins: ${ bCoinsMax }`;

    const createMeetupElementHandler = async () => {
        const elementData = {
            rewardId: id
        };

        const createMeetupElementResult = await CRUDfunctions.create("/rewards/meetups/elements", elementData);
    }

    const updateNextCompletionData = async (rewardId) => {
        const updatedNextCompletionData = await CRUDfunctions.read(`/rewards/next_completion_data/${ rewardId }`);
        setNextCompletionData(updatedNextCompletionData);
    }

    useEffect( () => {
        setTimeout( () => {
            updateNextCompletionData(id);
        }, Math.max(0, new Date(nextCompletionData.validUntil).getTime() - (new Date()).getTime())) ;
    }, [ id, nextCompletionData ])

    //@TODO: Modal erstellen, dass einen Text und eine Frage für das Event übernimmt
    const addRewardModalText = `
            <p>Beschreibung: ${ description }</p>

            Willst du diese Belohnung zum nächstem Treffen hinzufügen?`;

    const rewardBar = (
        <BarElementx1
            title={ title }
            subtitle={ subtitle } 
            pointsElement={
                <BCoins value={ nextCompletionData.bCoins } />
            }
            progressStatus={ (nextCompletionData.bCoins / bCoinsMax) * 100 }
            icon={ icon }/>
    );
    
    //@TODO: WarningModal hinzufügen, wenn die Belohnung bereits im Vorschlag vorhanden ist
    return (
        <>
            { isAddingRewardToMeetupAllowed &&
                <EventConfirmationButton
                    eventFunction={ createMeetupElementHandler }
                    confirmationButtonText="Hinzufügen"
                    confirmationButtonLoadingText="Hinzufügen..."
                    modalSuccessTitle={ title }
                    modalSuccessContent="Die Belohnung wurde zum Vorschlag für das nächste Treffen hinzugefügt"
                    modalEventConfirmationTitle={ title }
                    modalEventConfirmationContent={ `${addRewardModalText}` } >
                    { rewardBar }
                </EventConfirmationButton>
            }
            { !isAddingRewardToMeetupAllowed &&
                rewardBar
            }
        </>
    );
}


export default Rewards;