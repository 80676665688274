import Balance from "../../components/Balance";
import BCoins from "../../components/BCoins";

import styles from "../Meetups/Meetups.module.css";

function MeetupData({ timestamp, reward, isCompleted, bCoinsFromReward, cashoutFromReward, balance, cashoutFromBalance, totalCashout, comment }) {
    //@TODO: isCompleted ergibt sich dadurch, ob der timestamp in der Zukunft liegt

    //@TODO: muss in eine utility-Funktion (wird auch in Activity verwendet)
    const dateTimestamp = new Date(timestamp);
    const day = `${ (dateTimestamp.getDate() < 10) ? "0" : "" }${ dateTimestamp.getDate() }`;
    const month = `${ (dateTimestamp.getMonth() < 9) ? "0" : "" }${ dateTimestamp.getMonth() + 1 }`;
    const year = `${ dateTimestamp.getFullYear() }`;
    const hour = `${ (dateTimestamp.getHours() < 10) ? "0" : ""}${ dateTimestamp.getHours() }`;
    const minute = `${ (dateTimestamp.getMinutes() < 10) ? "0" : ""}${ dateTimestamp.getMinutes() }`;

    const dateString = (dateTimestamp.getTime() > 0) ?
        `${ day }.${ month }.${ year }` :
        "Nicht festgelegt";
    const timeString = (dateTimestamp.getTime() > 0) ?
        `${ hour }:${ minute }` :
        "Nicht festgelegt";

    return (
        <div className="w-100 px-3 py-2">
            <div className="d-flex my-1">
                <div className="col-3">Datum:</div>
                <div className={ styles['font-emphasis'] } >
                    { dateString }
                </div>
            </div>

            <div className="d-flex my-1">
                <div className="col-3">Uhrzeit:</div>
                <div className={ styles['font-emphasis'] }>
                    { timeString }
                </div>
            </div>

            <div className="d-flex my-1">
                <div className="col-12">Belohnungen:</div>
            </div>

            <div className="d-flex my-1">
                <div className={ `col-5 ${ styles['font-emphasis'] }` }>
                    { reward }
                </div>
                <div className="col-3 d-flex justify-content-end">
                    <BCoins value={ bCoinsFromReward } />
                </div>
                <div className="col-1 d-flex justify-content-center">&rarr;</div>
                <div className="col-3 d-flex justify-content-end">
                    <Balance balance={ cashoutFromReward } />
                </div>
            </div>

            <div className="d-flex my-4">
                <div className={ `col-5 ${ styles['font-emphasis'] }` }>Guthaben:</div>
                { (!isCompleted) &&
                    <>
                        <div className="col-3 d-flex justify-content-end">
                            <Balance balance={ balance } />
                        </div>
                        <div className="col-1 d-flex justify-content-center">&rarr;</div>
                    </>
                }
                { (isCompleted) &&
                    <div className="col-4" />
                }
                <div className="col-3 d-flex justify-content-end">
                    <Balance balance={ cashoutFromBalance } />
                </div>
            </div>

            <div className="d-flex my-3">
                <div className={ `col-9 ${ styles['font-emphasis'] } py-2` }>Gesamtauszahlung:</div>
                <div className="col-3 d-flex justify-content-end py-2 border-top">
                    <Balance balance={ totalCashout } />
                </div>
            </div>

            
            <div className="d-flex mt-4">
                <div className="col-4">Kommentar:</div>
            </div>

            <div className="d-flex my-2">
                <div className={ `col-12 ${ styles['comments-div']} px-2 py-1` }>
                    <div>
                        { (comment) && comment }
                        { (!comment) && "keine Kommentare" }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MeetupData;