import { useState } from "react";
import { useLoaderData } from "react-router-dom";

import CRUDfunctions from "../../lib/CRUDbackend";

import Topic from "./Workplace/Topic";
import InstructionPoints from "./Workplace/InstructionPoints";
import { TaskModal } from "../components/modals/TaskModal";
import { EditTopicModal } from "../components/modals/EditTopicModal";

import styles from "./Workplace/Workplace.module.css";

//import Story from "./Workplace/Story";

//@TODO: Standard erstellen, für eine Seite mit verwaltetem Daten-Array; Hier: Titel, Beschreibung, Status
function Workplace() {
    const userrole = parseInt(localStorage.userrole);

    const workplaceData = useLoaderData();

    const title = "Aufgabenliste";

    const instructionGoal = workplaceData.instructionGoal;

    const [ instructionBalance, setInstructionBalance ] = useState(workplaceData.instructionBalance);
    const [ stories, setStories ] = useState(workplaceData.stories);
    const [ tasks, setTasks ] = useState(workplaceData.tasks);
    
    const topLevelStories = stories.filter( (story) => story.parentStoryId === -1 );

    const createTaskHandler = async (parentStoryId, title, description, visibility) => {
        //@TODO: Check der Daten. Es können auch leere Tasks erstellt werden.
        
        const taskData = {
            parentStoryId: parentStoryId,
            title: title,
            description: description,
            visibility: visibility
        };
        
        //Üblicherweise würde nur der neu erstellte Task vom Server zurückgegeben werden.
        //Weil hier aber auch Stories betroffen sind (totalTasks, ...), werden alle
        //Tasks und Stories vom Server zurückgegeben und können neu gerendert werden
        const workplaceData = await CRUDfunctions.create("/workplace/", { taskData: taskData });

        setStories(workplaceData.stories);
        setTasks(workplaceData.tasks);
    }

    const updateTaskHandler = async (id, title, description) => {
        const taskData = {
            id: id,
            title: title,
            description: description
        };

        const updatedTask = await CRUDfunctions.update("/workplace/task/", { taskData: taskData });

        setTasks( currentTasks => [
            ...currentTasks.filter( task => task.id !== updatedTask.id),
            updatedTask
        ]);
    }

    const updateTaskStatusHandler = async (id) => {
        const taskData = {
            id: id
        };
        
        const workplaceData = await CRUDfunctions.update("/workplace/task/finish/", { taskData: taskData });

        setStories(workplaceData.stories);
        setTasks(workplaceData.tasks);
    }

    const updateTaskPlanningHandler = async (id, points) => {
        const elementData = {
            id: id,
            points: points
        };

        const workplaceData = await CRUDfunctions.update("/workplace/task/planning", { elementData: elementData });
        setInstructionBalance(workplaceData.instructionBalance);
        setStories(workplaceData.stories);
        setTasks(workplaceData.tasks);
    }

    const updateTopicHandler = async (id, title, description) => {
        //@TODO: Statt Topics und Tasks jedes Mal zu unterscheiden: updateElementHandler = async (type, id, title, description); type = TASK/TOPIC
        const topicData = {
            id: id,
            title: title,
            description: description
        };

        const updatedTopic = await CRUDfunctions.update("/workplace/topic/", { topicData: topicData });

        setStories( currentTopics => [
            ...currentTopics.filter( topic => topic.id !== updatedTopic.id),
            updatedTopic
        ]);
    }

    const deleteTaskHandler = async (id) => {
        const taskData = {
            id: id
        };
        
        //If the task to delete is the last one in the topic, the topic will also be deleted. If the
        //deleted topic is the last element in the next-level-topic, the next-level-topic wil also be deleted and so on.
        const workplaceData = await CRUDfunctions.drop("/workplace/task/", { taskData: taskData });
        
        setStories(workplaceData.stories);
        setTasks(workplaceData.tasks);

        return { wasTopicConvertedToTask: workplaceData.wasTopicConvertedToTask, newParentTopicId: workplaceData.newParentTopicId };
        //Gehe durch alle Elternelemente und ziehe den Task von den offenen und gesamten Tasks ab
        /*deletedElementsObj.stories.forEach( element => {
            element.isTask            
        });*/
    }

    const convertTaskToStoryHandler = async (id) => {
        const taskData = {
            id: id
        };

        const workplaceData = await CRUDfunctions.update("/workplace/task/convert/", { taskData: taskData });

        setStories(workplaceData.stories);
        setTasks(workplaceData.tasks);

        return true;
    }

    const deleteTaskUIHandler = async (id) => {
        const deleteTaskResult = await deleteTaskHandler(id);
       
        if (deleteTaskResult.wasTopicConvertedToTask) {
            const parentTopicButton = document.getElementById(`topicButton-${ deleteTaskResult.newParentTopicId }`);
            parentTopicButton.click();
        }
    }

    const updateElementVisibilityHandler = async (elementType, id, visibility) => {
        const elementData = {
            id: id,
            visibility: visibility
        }

        const workplaceData = await CRUDfunctions.update("/workplace/" + elementType + "/visibility/", { elementData: elementData });

        if (!workplaceData.error) {
            setStories(workplaceData.stories);
            setTasks(workplaceData.tasks);

            return { success: true };
        } else {
            return { error: workplaceData.error };
        }
    }

    return (
        <>
            <TaskModal
                instructionBalance={ instructionBalance }
                updateTaskHandler={ updateTaskHandler }
                updateTaskStatusHandler={ updateTaskStatusHandler }
                updateTaskPlanningHandler={ updateTaskPlanningHandler }
                deleteTaskUIHandler={ deleteTaskUIHandler }
                convertTaskToStoryHandler={ convertTaskToStoryHandler }
                updateElementVisibilityHandler={ updateElementVisibilityHandler } />

            <h2 className="d-flex justify-content-center w-100">
                { title }
            </h2>

            { topLevelStories.map( (story) => <Topic
                                                key={ story.id }
                                                id={ story.id }
                                                stories={ stories }
                                                tasks={ tasks }
                                                toplevel={ true }
                                                createTaskHandler={ createTaskHandler }
                                                updateElementVisibilityHandler={ updateElementVisibilityHandler } /> ) }

            <nav className="navbar fixed-bottom py-0">
                <div className="container-fluid px-0">
                    <div className={ `d-flex w-100 justify-content-center ${ styles['footer'] }`} data-bs-toggle="collapse" data-bs-target="#instructionBalanceBar">
                        <div className="col-9">
                            <div className={ `${ styles['instruction-bonuslist-font'] } py-1 ps-4` }>
                                Nächstes Ziel
                            </div>
                            <div className={ `d-flex ${ styles['instruction-bonuslist-emphasis'] } ps-5 pb-2` }>
                                Abschluss von { instructionGoal.goalCompletedElements } Anordnungen
                            </div>
                        </div>
                        
                        <div className="d-flex justify-content-end align-items-center col-3 pe-4">
                            { (userrole === 100) &&
                                <InstructionPoints points={ instructionBalance } scaling="1.3" />
                            }
                        </div>
                    </div>
                    <div className="navbar-collapse collapse" id="instructionBalanceBar">
                        <ul className={ `overflow-auto ${ styles['instruction-bonuslist'] } px-0 my-0` }>
                            <div className={ `${ styles['instruction-bonuslist-emphasis'] } py-3 ps-4` }>
                                { (instructionGoal.currentCompletedElements === 1) &&
                                    "Bisher wurde 1 Anordnung abgeschlossen"
                                }
                                { (instructionGoal.currentCompletedElements !== 1) &&
                                    `Bisher wurden ${ instructionGoal.currentCompletedElements } Anordnung abgeschlossen`
                                }
                            </div>
                            <div className={ `${ styles['instruction-bonuslist-font'] } px-4` }>
                                Unter "Anordnung" kannst du eine Aufgabe oder einen ganzen Aufgabenblock zur Bearbeitung anordnen. Lege für die Anordung eine Anzahl von Anordnungs-Punkten fest. Sobald die Anordnung abgeschlossen wurde, kassierst du Punkte aus dem Punktepool. Dabei entsprechen 100 abgeschlossene Anordnungs-Punkte einem Punkt aus dem Punktepool. Jeder Punkt den du kassierst, wird automatisch in 1€ Guthaben umgewandelt und deinem Konto gutgeschrieben.
                            </div>
                            <div className={ `d-flex flex-column align-items-center ${ styles['instruction-bonuslist-emphasis'] } py-3` }>
                                <div>Bonus bei Zielerreichung</div>
                                <InstructionPoints className="py-2" points={ 100 } />
                            </div>
                        </ul>
                    </div>
                </div>
            </nav>

            <EditTopicModal updateTopicHandler={ updateTopicHandler } />
        </>
    );
}

export default Workplace;