import { useEffect, useState } from "react";

import AddButton from "./AddButton";
import StoryButton from "./StoryButton";
import { TaskButton } from "../../components/modals/TaskModal";

import styles from "./Workplace.module.css"
import { EditTopicButton } from "../../components/modals/EditTopicModal";

function Topic({ id, stories, tasks, toplevel, createTaskHandler, updateElementVisibilityHandler }) {
    const userrole = parseInt(localStorage.userrole);
    
    const thisStory = stories.filter( story => story.id === id)[0];
    const subStories = stories.filter( story => story.parentStoryId === id );
    const storyTasks = tasks.filter( task => task.parentStoryId === id );

    const [ visibility, setVisibility ] = useState(thisStory.visibility);
    useEffect( () => setVisibility(thisStory.visibility), [ thisStory.visibility ]);

    const [ isVisibilitySwitchDisabled, setIsVisibilitySwitchDisabled ] = useState(thisStory.isPlanned);

    const handleTopicVisibilityChange = async () => {
        setIsVisibilitySwitchDisabled(true);

        const result = await updateElementVisibilityHandler("topic", id, !visibility);
        
        setIsVisibilitySwitchDisabled(false);

        if (!result.error) {
            setVisibility( currentVisibility => !currentVisibility );
        } else {
            //@TODO: Error-Handling (Warnungs-Modal)
        }
    }

    return (
        <div className={ `accordion ${ (toplevel) && "py-3" }` } id={ `story-${ id }` }>
            <div className={ `accordion-item ${ (!toplevel) && "border-0" }` }>
                <h2 className="accordion-header">
                    <button id={ `topicButton-${ id }` } className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={ `#storyElements-${ id }` }>
                        <div className={ styles['story-btn-active'] }>
                            { thisStory.title }
                        </div>
                    </button>
                </h2>

                <div id={ `storyElements-${ id }` } className="accordion-collapse collapse show" data-bs-parent={ `#story-${ id }` }>
                    <div className="accordion-body">
                        
                        { (thisStory.parentStoryId > -1) &&
                            <>
                                { (userrole === 101) &&
                                    <>
                                        { (thisStory.isPlanned) &&
                                            <div className={ `${ styles['task-modal-subtitle-points'] } ms-3 mt-1` }>
                                                Dieser Aufgabenblock wurde mit { thisStory.points }P angeordnet
                                            </div>
                                        }
                                    </>
                                }
                                { (userrole === 100) &&
                                    <>
                                        { (thisStory.isPlanned) &&
                                            <div className={ `${ styles['task-modal-subtitle-points'] } ms-3 mt-1` }>
                                                Du hast diesen Aufgabenblock mit { thisStory.points }P angeordnet
                                            </div>
                                        }
                                    </>
                                }
                            </>
                        }

                        <nav className="d-flex justify-content-between">
                            <div className="nav nav-underline ms-3" id="nav-tab" role="tablist">
                                <button
                                    className="nav-link active"
                                    id={ `nav-storyElements-${ id }-tab` }
                                    data-bs-toggle="tab"
                                    data-bs-target={ `#nav-storyElements-${ id }` }
                                    type="button"
                                    role="tab"
                                    aria-controls={ `nav-storyElements-${ id }` }
                                    aria-selected="true">
                                        Aufgaben
                                </button>

                                <button
                                    className="nav-link"
                                    id={ `nav-storyDescription-${ id }-tab` }
                                    data-bs-toggle="tab"
                                    data-bs-target={ `#nav-storyDescription-${ id }` }
                                    type="button"
                                    role="tab"
                                    aria-controls={ `nav-storyDescription-${ id }` }
                                    aria-selected="false">
                                        Beschreibung
                                </button>
                            </div>
                            
                            { (userrole === 101) &&
                                <div className="form-check form-switch mt-2 pe-4">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        role="switch"
                                        checked={ visibility } 
                                        onChange={ handleTopicVisibilityChange }
                                        disabled={ isVisibilitySwitchDisabled } />
                                </div>
                            }
                        </nav>
                        <div className="tab-content" id="nav-tabContent">
                            <div className="tab-pane fade show active" id={ `nav-storyElements-${ id }` } role="tabpanel" aria-labelledby={ `nav-storyElements-${ id }-tab` } tabIndex="0">
                                { 
                                    subStories.map( (subStory) => {
                                        return (
                                            <button key={ subStory.id } className="accordion-button collapsed p-0" type="button" data-bs-toggle="collapse" data-bs-target={ `#subStory-${ subStory.id }` }>
                                                <StoryButton
                                                    title={ subStory.title }
                                                    points={ subStory.points }
                                                    isPlanned={ subStory.isPlanned }
                                                    openTasks={ subStory.openTasks }
                                                    totalTasks={ subStory.totalTasks }
                                                    visibility={ subStory.visibility } />
                                            </button>
                                        );
                                    })
                                }

                                { storyTasks.map( (task) => <TaskButton
                                                                key={ task.id }
                                                                id={ task.id }
                                                                title={ task.title }
                                                                description={ task.description }
                                                                isPlanned={ task.isPlanned }
                                                                points={ task.points }
                                                                processingStatus={ task.processingStatus }
                                                                visibility={ task.visibility } /> ) }

                                { (userrole !== 100) &&
                                    <>
                                        {/*In einer angeordneten Story, die bereits abgeschlossen wurde, darf nichts mehr bearbeitet werden (auch keine neuen Tasks ersellen)*/}
                                        { !((thisStory.isPlanned) && (thisStory.isCompleted)) &&
                                            <AddButton id={ id } createTaskHandler={ createTaskHandler } />
                                        }
                                    </>
                                }
                            </div>
                            
                            <div className={ `tab-pane fade ${ styles['story-description'] }` } id={ `nav-storyDescription-${ id }` } role="tabpanel" aria-labelledby={ `nav-storyDescription-${ id }-tab` } tabIndex="0">
                                { thisStory.description }

                                { (userrole === 101) &&
                                    <div className="d-flex justify-content-end">
                                        <EditTopicButton
                                            id={ thisStory.id }
                                            title={ thisStory.title }
                                            description={ thisStory.description } />
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>

                {
                    subStories.map( (subStory) => {
                        return (
                            <div key={ subStory.id } id={ `subStory-${ subStory.id }` } className="accordion-collapse collapse" data-bs-parent={ `#story-${ id }` }>
                                <div className="accordion-body">
                                    <div>
                                        <Topic
                                            id={ subStory.id }
                                            stories={ stories }
                                            tasks={ tasks }
                                            toplevel={ false }
                                            createTaskHandler={ createTaskHandler }
                                            updateElementVisibilityHandler={ updateElementVisibilityHandler } />
                                    </div>
                                </div>
                            </div>
                        );
                    })
                }
            </div>
        </div>
    );
}

export default Topic;