import BarElementx1 from "../../components/BarElementx1";

import InstructionPoints from "./InstructionPoints"; 

import finishedIcon from "../../../img/check_tick.png";
import topicIcon from "../../../img/topic.png";
import invisibleIcon from "../../../img/invisible.png";

function StoryButton({ title, points, isPlanned, openTasks, totalTasks, visibility }) {
    const subtitle = ( () => {
        if (openTasks === 0) {
            return "Keine offenen Aufgaben";
        } else if (openTasks === 1) {
            return "1 offene Aufgabe";
        } else if (openTasks > 1) {
            return `${ openTasks } offene Aufgaben`;
        } else {
            return "";
        }
    })();

    const icon = ( () => {
        if (visibility) {
            if (openTasks === 0) {
                return finishedIcon;
            } else {
                return topicIcon;
            }
        } else {
            return invisibleIcon;
        }
    })();

    const pointsElement = (points > 0) ? 
                            <InstructionPoints points={ points } /> :
                            "";

    return <BarElementx1 
                title={ title }
                subtitle={ subtitle }
                pointsElement={ pointsElement }
                isHighlighted={ isPlanned }
                progressStatus={ (1-openTasks/totalTasks)*100 }
                icon={ icon } />;
}

export default StoryButton;