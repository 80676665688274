import { createPortal } from "react-dom";
import { EventResponseButton } from "./EventResponseModal";
import { useEffect, useState } from "react";

function EventConfirmationButton({ text, eventFunction, confirmationButtonText, confirmationButtonLoadingText, modalSuccessTitle, modalSuccessContent, modalEventConfirmationTitle, modalEventConfirmationContent }) {
    const handleButtonClick = () => {
        const hiddenEventFunction = document.getElementById("hiddenEventFunction");
        hiddenEventFunction.onclick = eventFunction;
    }

    return (
        <button
            type="button"
            className="btn btn-outline-secondary"
            onClick={ handleButtonClick }
            data-bs-dismiss="modal"
            data-bs-toggle="modal"
            data-bs-target="#EventConfirmationModal"
            data-bs-confirmationbuttontext={ confirmationButtonText }
            data-bs-confirmationbuttonloadingtext={ confirmationButtonLoadingText }
            data-bs-modalsuccesstitle={ modalSuccessTitle }
            data-bs-modalsuccesscontent={ modalSuccessContent }
            data-bs-modaleventconfirmationtitle={ modalEventConfirmationTitle }
            data-bs-modaleventconfirmationcontent={ modalEventConfirmationContent }>
                { text }
        </button>
    );
}

function EventConfirmationModal() {
    const [ confirmationButtonText, setConfirmationButtonText ] = useState("");
    const [ confirmationButtonLoadingText, setConfirmationButtonLoadingText ] = useState("");
    const [ modalSuccessTitle, setModalSuccessTitle ] = useState("");
    const [ modalSuccessContent, setModalSuccessContent ] = useState("");

    useEffect(() => {
        const EventConfirmationModal = document.getElementById("EventConfirmationModal");

        EventConfirmationModal.addEventListener('show.bs.modal', event => {
            // Button that triggered the modal
            const button = event.relatedTarget
            setConfirmationButtonText(button.getAttribute("data-bs-confirmationbuttontext"));
            setConfirmationButtonLoadingText(button.getAttribute("data-bs-confirmationbuttonloadingtext"));
            setModalSuccessTitle(button.getAttribute("data-bs-modalsuccesstitle"));
            setModalSuccessContent(button.getAttribute("data-bs-modalsuccesscontent"));
            const modalTitle = document.getElementById("EventConfirmationModal-title");
            const modalContent = document.getElementById("EventConfirmationModal-content");
                modalTitle.innerHTML = button.getAttribute("data-bs-modaleventconfirmationtitle")
                modalContent.innerHTML = button.getAttribute("data-bs-modaleventconfirmationcontent")
          })
    }, []);

    return createPortal(
        <div className="modal fade" id="EventConfirmationModal" tabIndex="-1">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header border-0">
                        <h1 id="EventConfirmationModal-title" className="modal-title fs-5" />

                        { /* @TODO: btn-close-white sollte nicht hier gesetzt werden sondern abhängig von Primary-Color und dark-Mode in SASS */ }
                        <button type="button" id="EventConfirmationModal-closeButton" className="ms-auto btn-close btn-close-white" data-bs-dismiss="modal" />
                    </div>

                    <div id="EventConfirmationModal-content" className="modal-body pt-0" />

                    <div className="modal-footer border-0 pt-0">
                        <button type="button" className="btn btn-outline-secondary" data-bs-dismiss="modal">Abbrechen</button>
                        <EventResponseButton
                            className="btn btn-primary"
                            text={ confirmationButtonText }
                            loadingText={ confirmationButtonLoadingText }
                            modalSuccessTitle={ modalSuccessTitle }
                            modalSuccessContent={ modalSuccessContent } />
                    </div>
                </div>
            </div>
        </div>,
        document.body
    );
}

export {
    EventConfirmationButton,
    EventConfirmationModal
}