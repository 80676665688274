import bCoinIcon from "../../img/bCoin.png";

import styles from "../sites/Activities/Activities.module.css";

function BCoins({ value, inline, className, scaling }) {
    const _scaling = 1; //(scaling) ? scaling : 1;
    const iconSize = 1.5 * _scaling;
    const fontSize = _scaling

    const display = (inline) ? "d-inline" : "d-flex";
    const cssClasses = (className) ? className : "";

    return (
        <div className={ `${ display } ${ cssClasses }` } style={{ transform: `scale(${ (scaling) ? scaling : 1 })` }}>
            <div className="d-inline">
                <img style={{ width: `${ iconSize }` }} src={ bCoinIcon } className={ `${ styles['icon-activityPoints'] } pe-1` } alt="activity points icon" />
            </div>

           <div style={{ /*width: "1.7rem",*/ fontSize: `${ fontSize }rem` }} className={ `d-inline ${ styles['font-activityPoints'] }` } >{ value }</div>
        </div>
    );
}

export default BCoins;